import React, { useState, useEffect } from 'react'
import Footer from './inc/Footer'
import Header from './inc/Header'
import Sidebar from './inc/Sidebar'
import Badge from 'react-bootstrap/Badge'
import Loading from 'react-fullscreen-loading'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'

const Inpres = () => {
    const userId = window.sessionStorage.getItem("id")
    const userType = window.sessionStorage.getItem("type")

    const [loader, setLoader] = useState(false)
    const [paperFileLoader, setPaperFileLoader] = useState(false)
    const [paymentSlipLoader, setPaymentSlipLoader] = useState(false)
    const [publishLoader, setPublishLoader] = useState(false)

    const [success, setSuccess] = useState(false)

    const [volumeList, setVolumeList] = useState([])
    const [issueList, setIssueList] = useState([])
    const [typeList, setTypeList] = useState([])
    const [manuList, setManuList] = useState([])
    const [paperList, setPaperList] = useState([])

    const [id, setId] = useState("")
    const [volume, setVolume] = useState("")
    const [issue, setIssue] = useState("")
    const [type, setType] = useState("")
    const [manuscript, setManuscript] = useState(0)
    const [doi, setDoi] = useState("")
    const [title, setTitle] = useState("")
    const [abstract, setAbstract] = useState("")
    const [authors, setAuthors] = useState("")
    const [keywords, setKeywords] = useState("")
    const [userName, setUserName] = useState("")
    const [userEmail, setUserEmail] = useState("")
    const [year, setYear] = useState("")
    const [pNo, setPNo] = useState("")

    const [paperFile, setPaperFile] = useState("")
    const [paperFilePath, setPaperFilePath] = useState("")

    const [paymentSlip, setPaymentSlip] = useState("")
    const [paymentSlipPath, setPaymentSlipPath] = useState("")

    const [update, setUpdate] = useState(false)

    const [visible, setVisible] = useState("Pak")

    React.useEffect(() => {
        window.scrollTo(0, 0)
        getPaperList()
    }, [])

    async function getPaperList() {
        setLoader(true)
        const formData = new FormData()
        formData.append('visible', visible)
        try {
            let dataRes = await fetch(window.api + "getAllInpressAdmin", {
                method: 'POST',
                body: formData,
            })

            dataRes = await dataRes.json()
            if (dataRes) {
                setPaperList(dataRes.all_inpress)
                setManuList(dataRes.manu_list)
                setLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function getSelectedPaperList(vis) {
        setVisible(vis)
        setLoader(true)
        const formData = new FormData()
        formData.append('visible', vis)
        try {
            let dataRes = await fetch(window.api + "getAllInpressAdmin", {
                method: 'POST',
                body: formData,
            })

            dataRes = await dataRes.json()
            if (dataRes) {
                setPaperList(dataRes.all_inpress)
                setLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function getManuscript(manuscript) {
        setManuscript(manuscript)
        setLoader(true)
        const formData = new FormData()
        formData.append('manu_id', manuscript)
        try {
            let sendRes = await fetch(window.api + "getManuDataAdmin", {
                method: 'POST',
                body: formData,
            })

            sendRes = await sendRes.json()
            if (sendRes) {
                setTitle(sendRes.manuscript.manu_title)
                setAbstract(sendRes.manuscript.abstract)
                setAuthors(sendRes.authors)
                setUserName(sendRes.manuscript.user_name)
                setUserEmail(sendRes.manuscript.user_email)
                setYear(sendRes.manuscript.user_email)
                setPNo(sendRes.manuscript.user_email)
                setLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function handlePaperFile(file) {
        setPaperFile(file[0])
        setPaperFileLoader(true)
        const formData = new FormData()
        formData.append('paperFile', file[0])
        try {
            let sendRes = await fetch(window.api + "uploadInpresPaperFile", {
                method: 'POST',
                body: formData,
            })

            sendRes = await sendRes.json()
            if (sendRes.path) {
                setPaperFilePath(sendRes.path)
                setPaperFileLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function clearPaperFile() {
        setPaperFile("")
        setPaperFilePath("")
    }

    async function addInpresPaper() {
        setPublishLoader(true)
        const formData = new FormData()
        formData.append('doi', doi)
        formData.append('title', title)
        formData.append('abstract', abstract)
        formData.append('authors', authors)
        formData.append('keywords', keywords)
        formData.append('user_name', userName)
        formData.append('user_email', userEmail)
        formData.append('manu_year', year)
        formData.append('manu_pno', pNo)
        formData.append('paper_file', paperFilePath)
        formData.append('country', visible)
        formData.append('updated_by', userId)

        try {
            let sendRes = await fetch(window.api + "addInpresPaper", {
                method: 'POST',
                body: formData,
            })

            sendRes = await sendRes.json()
            if (sendRes.success) {
                getPaperList(issue); setManuscript(""); setDoi("");
                setTitle(""); setAbstract(""); setAuthors(""); setKeywords(""); setUserName(""); setUserEmail("");
                setYear(""); setPNo(""); setPaperFile(""); setPaperFilePath(""); setPaymentSlip("");
                setPaymentSlipPath(""); setPublishLoader(false); setSuccess(true);
            }
        } catch (err) {
            console.log(err)
        }
    }

    function editPaper(id, title, authors, doi, abstract, keywords, file, count) {
        setUpdate(true);
        setId(id); setTitle(title); setAuthors(authors);
        setDoi(doi); setAbstract(abstract); setKeywords(keywords); setPaperFilePath(file); setVisible(count);
    }

    async function updatePaper() {
        setPublishLoader(true)
        const formData = new FormData()
        formData.append('id', id)
        formData.append('doi', doi)
        formData.append('title', title)
        formData.append('abstract', abstract)
        formData.append('authors', authors)
        formData.append('keywords', keywords)
        formData.append('paper_file', paperFilePath)
        formData.append('updated_by', userId)
        formData.append('country', visible)

        try {
            let sendRes = await fetch(window.api + "updateInpresPaper", {
                method: 'POST',
                body: formData,
            })

            sendRes = await sendRes.json()
            if (sendRes.success) {
                setUpdate(false);
                getPaperList(); setId(""); setTitle(""); setAuthors("");
                setDoi(""); setAbstract(""); setKeywords(""); setPaperFilePath("");
                setPublishLoader(false); setSuccess(true);
            }
        } catch (err) {
            console.log(err)
        }
    }

    const [countryList, setCountryList] = useState([]);
    const [paperId, setPaperId] = useState("");
    const [show, setShow] = useState(false);
    const [countryLoader, setCountryLoader] = useState(false);
    const [visCountry, setVisCountry] = useState();

    const [savingCountryLoader, setSavingCountryLoader] = useState(false);

    const handleClose = () => {
        setShow(false);
        setCountryList([]);
        setVisCountry("");
    }

    async function addVisible(papId) {
        setPaperId(papId)
        setShow(true);
        setCountryLoader(true);
        let datares = await fetch(window.api + "getCountries")
        datares = await datares.json()
        if (datares) {
            datares.countryList.map(c => (
                countryList.push({
                    value: c.country_code,
                    label: c.country_name,
                })
            ))
            setCountryLoader(false)
        }
    }

    function handleVisCountry(data) {
        setVisCountry(data)
    }

    async function saveCountries() {
        setSavingCountryLoader(true)
        const formData = new FormData()
        formData.append('paper_id', paperId)
        formData.append('type', 2)
        formData.append('vis_country', JSON.stringify(visCountry))

        try {
            let sendRes = await fetch(window.api + "saveCountries", {
                method: 'POST',
                body: formData,
            })

            sendRes = await sendRes.json()
            if (sendRes.success) {
                setSavingCountryLoader(false);
                setSuccess(true);
                handleClose();
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function delPaper(id) {
        setLoader(true)
        const formData = new FormData()
        formData.append('id', id)

        try {
            let sendRes = await fetch(window.api + "delInpresPaper", {
                method: 'POST',
                body: formData,
            })

            sendRes = await sendRes.json()
            if (sendRes.success) {
                getPaperList();
                setLoader(false); setSuccess(true);
            }
        } catch (err) {
            console.log(err)
        }
    }

    const [gettingCountryLoader, setGettingCountryLoader] = useState(false);
    const [showCountries, setShowCountries] = useState(false);
    const [showCountryList, setShowCountryList] = useState([]);
    const [showCountryId, setShowCountryId] = useState('');

    const handleCloseCountries = () => {
        setShowCountries(false);
        setShowCountryList([])
        setShowCountryId('')
    }

    async function getShowCountries(id) {
        setShowCountryId(id)
        setGettingCountryLoader(true)
        setShowCountries(true)
        const formData = new FormData()
        formData.append('pub_id', id)

        try {
            let sendRes = await fetch(window.api + "getShowCountries", {
                method: 'POST',
                body: formData,
            })

            sendRes = await sendRes.json()
            if (sendRes) {
                setGettingCountryLoader(false);
                setShowCountryList(sendRes.countries)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const [deletingLoader, setDeletingLoader] = useState(false);
    const [showId, setShowId] = useState(false);

    async function deleteCountry(id) {
        setShowId(id)
        setDeletingLoader(true)
        const formData = new FormData()
        formData.append('show_id', id)

        try {
            let sendRes = await fetch(window.api + "deleteShowCountry", {
                method: 'POST',
                body: formData,
            })

            sendRes = await sendRes.json()
            if (sendRes.success) {
                setDeletingLoader(false)
                getShowCountries(showCountryId)
                setShowId('')
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            {loader ? <><Loading loading loaderColor="#22C7B8" /></> : <></>}
            <div id="app">
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title>Add Countries</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {countryLoader ?
                            <i className='text-info'>Fetching Record.....</i> :
                            <>
                                <Select
                                    isMulti
                                    options={countryList}
                                    value={visCountry}
                                    onChange={handleVisCountry}
                                    class="form-control"
                                    placeholder="Select Countries You want to show paper"
                                />
                            </>}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={saveCountries}>
                            {savingCountryLoader ? "Saving..." : "Save Changes"}
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showCountries} onHide={handleCloseCountries}>
                    <Modal.Header>
                        <Modal.Title>Countries</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {gettingCountryLoader ?
                            <i className='text-info'>Fetching Record.....</i> :
                            <>
                                {Object.keys(showCountryList).length > 0 ?
                                    <>
                                        <table className="table table-sm table-striped table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Country Code</th>
                                                    <th>Country</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {showCountryList.map(c => (
                                                    <tr key={c.id}>
                                                        <td>{c.country_code}</td>
                                                        <td>{c.country_name}</td>
                                                        <td>
                                                            <button
                                                                className=' btn btn-danger btn-sm'
                                                                onClick={() => deleteCountry(c.id)}
                                                                disabled={deletingLoader}
                                                            >
                                                                {(showId == c.id) ? 'Deleting' : 'Delete'}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </> :
                                    <>
                                        <div className='text-center'>
                                            <i className='text-danger'>No Data</i>
                                        </div>
                                    </>}
                            </>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseCountries}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <div className="main-wrapper main-wrapper-1">
                    <div className="navbar-bg"></div>
                    <Header />
                    <Sidebar />
                    <div className="main-content">
                        <section className="section">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <center>
                                                <h4>Inpres Paper</h4>
                                            </center>
                                        </div>
                                        <div className="card-body">
                                            {(success) ?
                                                <>
                                                    <div class="alert alert-success alert-dismissible show fade">
                                                        <div class="alert-body">
                                                            <button class="close" data-dismiss="alert">
                                                                <span>&times;</span>
                                                            </button>
                                                            Done Successfully.
                                                        </div>
                                                    </div>
                                                </> :
                                                <></>}
                                            <div className='row'>
                                                {id ?
                                                    <></> :
                                                    <>
                                                        <div className='col-md-2 p-1'>
                                                            <select className='form-control' value={manuscript} onChange={(e) => getManuscript(e.target.value)}>
                                                                <option value="">--Manuscript--</option>
                                                                {Object.keys(manuList).length > 0 ? manuList.map(m => (
                                                                    <option value={m.id} key={m.id}>PJAS-{m.year}-{m.pno}</option>
                                                                )) : <></>}
                                                            </select>
                                                        </div>
                                                    </>}
                                                <div className='col-md-3 p-1'>
                                                    <input type="text" className='form-control' value={doi} onChange={(e) => setDoi(e.target.value)} placeholder="Enter Doi" />
                                                </div>
                                                <div className='col-md-7 p-1'>
                                                    <input type="text" className='form-control' value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter Title" />
                                                </div>
                                                <div className='col-md-12 p-1'>
                                                    <textarea type="text" className='form-control form-control-sm' value={abstract} onChange={(e) => setAbstract(e.target.value)} placeholder="Enter Abstract" />
                                                </div>
                                                <div className='col-md-6 p-1'>
                                                    <input type="text" className='form-control form-control-sm' value={authors} onChange={(e) => setAuthors(e.target.value)} placeholder="Enter Authors" />
                                                </div>
                                                <div className='col-md-6 p-1'>
                                                    <input type="text" className='form-control form-control-sm' value={keywords} onChange={(e) => setKeywords(e.target.value)} placeholder="Enter Keywords" />
                                                </div>
                                                <div className='col-md-3 p-1'>
                                                    <label>Paper File</label>
                                                    {paperFileLoader ?
                                                        <><p><i style={{ color: 'red' }}>Uploading...</i></p></> :
                                                        <>
                                                            {paperFilePath ?
                                                                <>
                                                                    <p>
                                                                        <Badge bg="success" style={{ color: 'white' }}>File Uploaded</Badge> <i onClick={clearPaperFile} className="fas fa-times"></i>
                                                                    </p>
                                                                </> :
                                                                <>
                                                                    <input
                                                                        className="form-control"
                                                                        type="file"
                                                                        onChange={e => handlePaperFile(e.target.files)}
                                                                    />
                                                                </>}
                                                        </>}
                                                </div>
                                                {(userType === 'SA' || userType === 'PI') ?
                                                    <>
                                                        <div className='col-md-2 p-1'>
                                                            <label>Visible</label>
                                                            <select className='form-control' value={visible} onChange={(e) => getSelectedPaperList(e.target.value)}>
                                                                <option value="Pak">All</option>
                                                                <option value="Other">Only Other</option>
                                                                <option value="Short">In Pak Only</option>
                                                            </select>
                                                        </div>
                                                    </> :
                                                    <></>}
                                                <div className='col-md-3 p-1'>
                                                    {update ?
                                                        <>
                                                            <button
                                                                className='btn btn-success'
                                                                style={{ marginTop: '30px' }}
                                                                disabled={!id || !doi || !title || !abstract || !authors || !keywords
                                                                    || !paperFilePath
                                                                }
                                                                onClick={updatePaper}
                                                            >{publishLoader ? 'Updating...' : 'Update'}</button>
                                                        </> :
                                                        <>
                                                            <button
                                                                className='btn btn-success'
                                                                style={{ marginTop: '30px' }}
                                                                disabled={!doi || !title || !abstract || !authors || !keywords
                                                                    || !paperFilePath
                                                                }
                                                                onClick={addInpresPaper}
                                                            >{publishLoader ? 'Publishing...' : 'Publish'}</button>
                                                        </>}
                                                </div>
                                            </div>
                                            <hr />
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    {Object.keys(paperList).length > 0 ?
                                                        <>
                                                            <div className="table-responsive">
                                                                <table className="table table-sm table-striped table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Action</th>
                                                                            <th>Files</th>
                                                                            {((userType === 'SA' && visible === 'Other') || (userType === 'PI' && visible === 'Other')) ?
                                                                                <>
                                                                                    <th>Countries</th>
                                                                                </> :
                                                                                <></>}
                                                                            <th>Title / Author</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {paperList.map(m => (
                                                                            <tr key={m}>
                                                                                <td className='text-center'>
                                                                                    <i
                                                                                        className="far fa-edit edit-icon-i text-info"
                                                                                        onClick={() => editPaper(m.id, m.title, m.paper_author, m.doi, m.paper_abstract, m.keywords, m.paper_file, m.country)}></i><br />
                                                                                    <i
                                                                                        className="far fas fa-backspace edit-icon-i text-danger"
                                                                                        onClick={() => delPaper(m.id)}></i>
                                                                                    {((userType === 'SA' && visible === 'Other') || (userType === 'PI' && visible === 'Other')) ?
                                                                                        <>
                                                                                            <br />
                                                                                            <i
                                                                                                className="fas fa-flag-checkered text-success i-tag"
                                                                                                onClick={() => addVisible(m.id)}></i>
                                                                                        </> :
                                                                                        <></>}
                                                                                </td>
                                                                                <td>
                                                                                    {(m.paper_file == null || m.paper_file == "") ?
                                                                                        <i>No File</i> :
                                                                                        <a href={window.storage + "downloadPaper/" + m.paper_file} rel="noreferrer" target="_blank" download>
                                                                                            <i className='fas fa-file-download'></i>
                                                                                        </a>
                                                                                    }<br />
                                                                                    {/* {(m.payment_slip == null || m.payment_slip == "") ?
                                                                                        <a></a> :
                                                                                        <a href={window.storage + "downloadPaymentSlip/" + m.payment_slip} rel="noreferrer" target="_blank" download>
                                                                                            <i className='fas fa-file-download'></i>
                                                                                        </a>
                                                                                    } */}
                                                                                </td>
                                                                                {((userType === 'SA' && visible === 'Other') || (userType === 'PI' && visible === 'Other')) ?
                                                                                    <>
                                                                                        <td>
                                                                                            <i
                                                                                                className='edit-icon-i text-info'
                                                                                                onClick={() => getShowCountries(m.id)}
                                                                                            >View</i>
                                                                                        </td>
                                                                                    </> :
                                                                                    <></>}
                                                                                <td>
                                                                                    {m.title}<br />
                                                                                    <i className='fas fa-user'></i> {m.paper_author}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </> :
                                                        <>
                                                            <div className='text-center'>
                                                                <i className='text-danger'>No Data</i>
                                                            </div>
                                                        </>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Inpres