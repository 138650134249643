import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Table from 'react-bootstrap/Table'
import { Link, useNavigate } from "react-router-dom"
import Badge from 'react-bootstrap/Badge'
import { confirmAlert } from 'react-confirm-alert'
import Loading from 'react-fullscreen-loading'

const SeProManu = () => {

    const userId = window.sessionStorage.getItem("id")

    const [loader, setLoader] = useState(false)

    const [seProManuListLoader, setSeProManuListLoader] = useState(false)

    const [seProManuscript, setSeProManuscript] = useState([])
    const [manuscriptAuthors, setManuscriptAuthors] = useState([])

    const [authorShow, setAuthorShow] = useState(false)
    const authorModalClose = () => setAuthorShow(false)
    const authorModalShow = (authors) => {
        setManuscriptAuthors(authors)
        setAuthorShow(true)
    }

    async function seProManuscriptData() {
        try {
            setSeProManuListLoader(true)
            let datares = await fetch(window.api + "getAllDmeSeProManuAdmin")
            datares = await datares.json()
            if (datares) {
                setSeProManuscript(datares.manuscripts)
                setSeProManuListLoader(false)
            }
        } catch (err) {
            console.log(err);
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
        seProManuscriptData()
    }, [])

    const [fetchRepLoader, setFetchRepLoader] = useState(false)
    const [repManuId, setRepManuId] = useState("")
    const [repManuFileId, setRepManuFileId] = useState("")
    const [repManuTitle, setRepManuTitle] = useState("")
    const [repManuYear, setRepManuYear] = useState("")
    const [repManuPno, setRepManuPno] = useState("")
    const [repManuData, setRepManuData] = useState([])
    const [repShow, setRepShow] = useState(false)
    const repModalClose = () => {
        setRepShow(false)
        setRepManuId("")
        setRepManuFileId("")
        setRepManuTitle("")
        setRepManuYear("")
        setRepManuPno("")
        setRepManuData([])
    }
    const repModalShow = async (manuId, manuFileId, manuTitle, manuYear, manuPno) => {

        setFetchRepLoader(true)
        setRepShow(true)
        setRepManuId(manuId)
        setRepManuFileId(manuFileId)
        setRepManuTitle(manuTitle)
        setRepManuYear(manuYear)
        setRepManuPno(manuPno)

        const formData = new FormData
        formData.append('manu_id', manuId)
        let result = await fetch(window.api + "getReviewingReport", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result) {
            setFetchRepLoader(false)
            setRepManuData(result.all_reviewing)
        }
    }

    const [fileLoader, setFileLoader] = useState(false)
    const [rejLoader, setRejLoader] = useState(false)
    const [rejManuId, setRejManuId] = useState("")
    const [rejManuFileId, setRejManuFileId] = useState("")
    const [rejManuUserName, setRejManuUserName] = useState("")
    const [rejManuUserEmail, setRejManuUserEmail] = useState("")
    const [rejManuTitle, setRejManuTitle] = useState("")
    const [rejManuFile, setRejManuFile] = useState("")
    const [rejManuFilePath, setRejManuFilePath] = useState("")
    const [rejManuComments, setRejManuComments] = useState("")
    const [rejManuYear, setRejManuYear] = useState("")
    const [rejManuPno, setRejManuPno] = useState("")

    const [rejShow, setRejShow] = useState(false)
    const rejModalClose = () => {
        setRejShow(false)
        setRejManuFile("")
        setRejManuFilePath("")
        setRejManuId("")
        setRejManuFileId("")
        setRejManuComments("")
        setRejManuYear("")
        setRejManuPno("")
        seProManuscriptData()
    }
    const rejModalShow = (manuId, manuFileId, manuTitle, manuYear, manuPno, manuUser, manuEmail) => {

        setRejManuId(manuId)
        setRejManuFileId(manuFileId)
        setRejManuUserName(manuUser)
        setRejManuUserEmail(manuEmail)
        setRejManuTitle(manuTitle)
        const comments = "Dear " + manuUser + "! I am very much thankful to you for considering ‘Pakistan Journal of Agricultural Sciences’ (PAKJAS) to publish your scientific and professional achievements. However, I am sorry to inform you that your manuscript ‘" + manuTitle + "’and manuscript No. PJAS-" + manuYear + "-" + manuPno + " has been considered ‘unsuitable’ for publication in PAKJAS, however this rejection is based on comparison with high quality manuscripts submitted  to VSOHJ, and doesn’t mean the rejection of your research findings. You can submit it in any other journal more related to your findings. Looking forward for your future submissions. Best Regards"
        setRejManuComments(comments)
        setRejManuYear(manuYear)
        setRejManuPno(manuPno)
        setRejShow(true)
    }

    const handleReplyFile = async (file) => {
        setRejManuFile(file[0])
        setFileLoader(true)
        const formData = new FormData()
        formData.append('replyFile', file[0])
        try {
            let replyUploadData = await fetch(window.api + "uploadReply", {
                method: 'POST',
                body: formData,
            })

            replyUploadData = await replyUploadData.json()
            console.warn(replyUploadData)
            if (replyUploadData.path) {
                setFileLoader(false)
                setRejManuFilePath(replyUploadData.path)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const clearReplyFile = () => {
        setRejManuFile("")
        setRejManuFilePath("")
    }

    async function rejToAuthor() {
        setRejLoader(true)
        const formData = new FormData()
        formData.append('rej_manu_id', rejManuId)
        formData.append('rej_manu_user_name', rejManuUserName)
        formData.append('rej_manu_user_email', rejManuUserEmail)
        formData.append('rej_manu_title', rejManuTitle)
        formData.append('rej_manu_file_id', rejManuFileId)
        formData.append('rej_manu_file_path', rejManuFilePath)
        formData.append('rej_manu_comments', rejManuComments)
        formData.append('rej_manu_year', rejManuYear)
        formData.append('rej_manu_pno', rejManuPno)
        formData.append('updated_by', userId)
        try {
            let rejData = await fetch(window.api + "rejToAuthorFromMe", {
                method: 'POST',
                body: formData,
            })

            rejData = await rejData.json()
            if (rejData.success) {
                setRejLoader(false)
                rejModalClose()
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function sendToMeFromDme(manuId) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure want to Send to M.E?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => returnTo(manuId)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    async function returnTo(manuId) {
        setLoader(true)
        const formData = new FormData()
        formData.append('me_manu_id', manuId)

        try {
            let rejData = await fetch(window.api + "sendToMeFromDme", {
                method: 'POST',
                body: formData,
            })

            rejData = await rejData.json()
            if (rejData.success) {
                setLoader(false)
                seProManuscriptData()
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function returnToMe(manuId) {

    }

    return (
        <>
            {loader ? <><Loading loading loaderColor="#22C7B8" /></> : <></>}
            <Modal show={authorShow} onHide={authorModalClose}>
                <Modal.Header>
                    <Modal.Title>Author Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <table className='table table-striped'>
                                <thead>
                                    <th>Name</th>
                                    <th>Affiliation</th>
                                </thead>
                                {Object.keys(manuscriptAuthors).length > 0 ? manuscriptAuthors.map(ad => (
                                    <tbody>
                                        <tr>
                                            <td>{ad.author_name}</td>
                                            <td>{ad.author_affiliation}</td>
                                        </tr>
                                    </tbody>
                                )) : <></>}
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={authorModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={repShow} size="xl" onHide={repModalClose}>
                <Modal.Header>
                    <Modal.Title>Reviewing Report for Manuscript</Modal.Title>
                    <Button variant="danger" onClick={repModalClose}>Close</Button>
                </Modal.Header>
                <Modal.Body scrollable={true}>
                    <div className="scrollbar" id="style-1">
                        <div className="container-fluid">
                            {(fetchRepLoader) ?
                                <>
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <i className="text-info">Fetching Record Please Wait..</i>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div className="col-md-2 col-2 p-0">

                                        <img alt="image" src="assets/img/logo.png" width="100px" />

                                    </div>
                                    <div className="col-md-8 col-8 p-0 report-heading">
                                        <h2>Pakistan Journal of Agricultural Sciences</h2>
                                    </div>
                                    <div className="col-md-2 col-2 p-0">

                                        {(repManuYear != 0 && repManuPno != 0) ?
                                            <>
                                                <h5>PJAS-{repManuYear}-{repManuPno}</h5>
                                            </> :
                                            <></>}
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <center>
                                                <h4>{repManuTitle}</h4>
                                            </center>
                                        </div>
                                    </div>

                                    {Object.keys(repManuData).length > 0 ?
                                        <>
                                            {repManuData.map((m, index) => {
                                                return (
                                                    <>
                                                        <Table bordered size="sm" className='border border-dark'>
                                                            <tbody>
                                                                <tr>
                                                                    <td><b>Manu File</b></td>
                                                                    <td>
                                                                        {(m.manu_file != null) ?
                                                                            <a href={window.storage + "downloadManuscript/" + m.manu_file} download={m.manu_file} rel="noreferrer" target="_blank">
                                                                                <i className="fas fa-file-download"></i>Manu File
                                                                            </a>
                                                                            : <i>No File</i>}
                                                                        {(m.file_status == 2) ?
                                                                            <>
                                                                                <span class="text-success"> (Eligible to Proceed)</span>
                                                                            </> :
                                                                            <></>}
                                                                    </td>
                                                                    <td><b>Supplementry File</b></td>
                                                                    <td>
                                                                        {(m.suplementry_file != null) ?
                                                                            <a href={window.storage + "downloadSuplementry/" + m.suplementry_file} download={m.suplementry_file} rel="noreferrer" target="_blank">
                                                                                <i className="fas fa-file-download"></i>Suplementry File
                                                                            </a> :
                                                                            <i className='text-info'>No File</i>}
                                                                    </td>
                                                                    <td><b>Cover File</b></td>
                                                                    <td>
                                                                        {(m.cover_file != null) ?
                                                                            <a href={window.storage + "downloadCover/" + m.cover_file} download={m.cover_file} rel="noreferrer" target="_blank">
                                                                                <i className="fas fa-file-download"></i>Cover File
                                                                            </a> :
                                                                            <i className='text-info'>No File</i>}
                                                                    </td>
                                                                </tr>
                                                                {(m.me_comments != null) ?
                                                                    <>
                                                                        <tr>
                                                                            <td style={{ width: '12%' }}>Managing Editor</td>
                                                                            <td colSpan={5}>
                                                                                {(m.me_file != null) ?
                                                                                    <a href={window.storage + "downloadReply/" + m.me_file} download={m.me_file} rel="noreferrer" target="_blank">
                                                                                        <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reply File
                                                                                    </a> : <a></a>}<br />
                                                                                <b>Comments:</b> {m.me_comments}
                                                                            </td>
                                                                        </tr>
                                                                    </> :
                                                                    <></>}
                                                                {(m.ret_se_comments != null) ?
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={6}>
                                                                                <center><b>Comments to Author</b></center>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '12%' }}>Section Editor</td>
                                                                            <td colSpan={5}>
                                                                                {(m.ret_se_file != null) ?
                                                                                    <a href={window.storage + "downloadReply/" + m.ret_se_file} download={m.ret_se_file} rel="noreferrer" target="_blank">
                                                                                        <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reply File
                                                                                    </a> : <a></a>}<br />
                                                                                <b>Comments:</b> {m.ret_se_comments}
                                                                            </td>
                                                                        </tr>
                                                                    </> :
                                                                    <></>}
                                                                {(m.edi_comments != null) ?
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={6}>
                                                                                <center><b>Comments to Author</b></center>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '12%' }}>Editorial Office</td>
                                                                            <td colSpan={5}>
                                                                                {(m.edi_file != null) ?
                                                                                    <a href={window.storage + "downloadReply/" + m.edi_file} download={m.edi_file} rel="noreferrer" target="_blank">
                                                                                        <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reply File
                                                                                    </a> : <a></a>}<br />
                                                                                <b>Comments:</b> {m.edi_comments}
                                                                            </td>
                                                                        </tr>
                                                                    </> :
                                                                    <></>}
                                                                {(m.se_accept != null) ?
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={6}>
                                                                                <center><b>Comments to M.E</b></center>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '12%' }}>Section Editor</td>
                                                                            <td colSpan={5}>
                                                                                {(m.se_file != null) ?
                                                                                    <>
                                                                                        <a href={window.storage + "downloadReply/" + m.se_file} download={m.se_file} rel="noreferrer" target="_blank">
                                                                                            <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reply File
                                                                                        </a><br /></> : <></>}
                                                                                <b>Accept Paper? </b>({m.se_accept})<br />
                                                                                <b>Comments:</b> {m.se_comments}
                                                                            </td>
                                                                        </tr>
                                                                    </> :
                                                                    <></>}
                                                                {Object.keys(m.assign_data).length > 0 ?
                                                                    <>
                                                                        <tr>
                                                                            <th style={{ width: '9%' }}>Reviewed By</th>
                                                                            <th colSpan={5}><center>Decision</center></th>
                                                                        </tr>
                                                                        {m.assign_data.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td>
                                                                                            {e.rev_fname}<br />
                                                                                            {e.created_at ? e.created_at.substr(0, 10) : <></>}
                                                                                        </td>
                                                                                        <td colSpan={5}>
                                                                                            {(e.assign_status == 0) ?
                                                                                                <><i className="text-danger">Reviewer Rejected Manuscript</i></> :
                                                                                                (e.assign_status == 1) ?
                                                                                                    <><i className="text-warning">Pending for Acceptance</i></> :
                                                                                                    (e.assign_status == 2) ?
                                                                                                        <><i className="text-info">Decision Pending</i></> :
                                                                                                        (e.assign_status == 3) ?
                                                                                                            <>
                                                                                                                <div className="row">
                                                                                                                    <div className="col-sm-6"><b>Do you want get credit for reviewing this manuscript?</b> ({e.paid})</div>
                                                                                                                    <div className="col-sm-6"><b>Subjects and contents are suitable for publications?</b> ({e.q1})</div>
                                                                                                                    <div className="col-sm-6"><b>Novelty of results is appropriate?</b> ({e.q2})</div>
                                                                                                                    <div className="col-sm-6"><b>Conclusions are well proven?</b> ({e.q3})</div>
                                                                                                                    <div className="col-sm-6"><b>Title is suitable?</b> ({e.q4})</div>
                                                                                                                    <div className="col-sm-6"><b>Number and quality of references are appropriate?</b> ({e.q5})</div>
                                                                                                                    <div className="col-sm-6"><b>The linguistic quality of paper is good?</b> ({e.q6})</div>
                                                                                                                    <div className="col-sm-6"><b>Reference is provided where data are reported?</b> ({e.q7})</div>
                                                                                                                </div>
                                                                                                                <hr />
                                                                                                                <div className="row">
                                                                                                                    <div className="col-md-6">
                                                                                                                        <b>Recomendations:  </b>
                                                                                                                        {
                                                                                                                            (e.r === 'r1') ?
                                                                                                                                <>
                                                                                                                                    <span>Accept in Present Form</span>
                                                                                                                                </> :
                                                                                                                                (e.r === 'r2') ?
                                                                                                                                    <>
                                                                                                                                        <span>Consider After Minor Revision</span>
                                                                                                                                    </> :
                                                                                                                                    (e.r === 'r3') ?
                                                                                                                                        <>
                                                                                                                                            <span>Reassess After Major Revision</span>
                                                                                                                                        </> :
                                                                                                                                        (e.r === 'r4') ?
                                                                                                                                            <>
                                                                                                                                                <span>Reject</span>
                                                                                                                                            </> :
                                                                                                                                            <></>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className="col-md-6">
                                                                                                                        {(e.reply_file_path != null) ?
                                                                                                                            <a href={window.storage + "downloadRevReply/" + e.reply_file_path} download={e.reply_file_path} rel="noreferrer" target="_blank" style={{ color: '#000000' }}>
                                                                                                                                <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reviewer Reply File
                                                                                                                            </a> : <a></a>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <hr />
                                                                                                                <div className="row">
                                                                                                                    <div className="col-sm-12">
                                                                                                                        <b>Confidential Comments to Executive Editor: </b>{e.ex_co}
                                                                                                                    </div>
                                                                                                                    <div className="col-sm-12">
                                                                                                                        <b>Comments to the Author: </b>{e.aut_co}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </> :
                                                                                                            <><i>Not Replied Yet</i></>}
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </> :
                                                                    <><tr><td>No Data</td></tr></>}
                                                            </tbody>
                                                        </Table>
                                                        {/* <hr /> */}
                                                    </>
                                                )
                                            })}
                                        </> :
                                        <><tr><td>No Data</td></tr></>}

                                </>}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={rejShow} size="lg" onHide={rejModalClose}>
                <Modal.Header>
                    <Modal.Title>Reject Manuscript</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className='row'>
                                <div className='col-md-4'>
                                    <label htmlFor="formFile" className="form-label">Reply File</label>
                                    {fileLoader ?
                                        <>
                                            <p><i style={{ color: 'red' }}>Uploading...</i></p>
                                        </> :
                                        <>
                                            {rejManuFilePath ?
                                                <>
                                                    <p>
                                                        <Badge bg="success" style={{ color: 'white' }}>Reply File Uploaded</Badge> <i onClick={clearReplyFile} className="fas fa-times"></i>
                                                    </p>
                                                </> :
                                                <>
                                                    <input
                                                        className="form-control"
                                                        type="file"
                                                        onChange={e => handleReplyFile(e.target.files)}
                                                    />
                                                </>
                                            }
                                        </>
                                    }
                                </div>
                                <div className='col-md-12'>
                                    <label htmlFor="formFile" className="form-label">Comments</label>
                                    <textarea value={rejManuComments} onChange={(e) => setRejManuComments(e.target.value)} className="form-control" rows={6} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={rejToAuthor} disabled={!rejManuComments || fileLoader}>
                        {rejLoader ? 'Rejecting! Please wait' : 'Reject'}
                    </Button>
                    <Button variant="secondary" onClick={rejModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {(seProManuListLoader) ?
                <><i>Fetching Record Please Wait....</i></> :
                <>
                    {Object.keys(seProManuscript).length > 0 ?
                        <>
                            <div className="table-responsive">
                                <table className="table table-sm table-striped table-bordered se-pro-manu">
                                    <thead>
                                        <tr>
                                            <th>Sr#</th>
                                            <th>Title / Author</th>
                                            <th>File</th>
                                            <th>S.E List</th>
                                            <th>Report</th>
                                            <th>M.E</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {seProManuscript.map(m => (
                                            <tr key={m}>
                                                <td>
                                                    {(m.manu_year == 0 && m.manu_pno == 0) ? m.manu_id : <>{"PJAS-" + m.manu_year + "-" + m.manu_pno}</>}<br />
                                                    {m.manu_date.substr(0, 10)}
                                                </td>
                                                <td>
                                                    {m.manu_title}<br />
                                                    <span className="icon-hand" onClick={() => authorModalShow(m.manu_authors)}><i className='fas fa-user'></i>
                                                        {(m.manu_id < 14245) ?
                                                            <>
                                                                {(m.manu_prvious_authors === null) ?
                                                                    <>
                                                                    </> :
                                                                    <>
                                                                        {
                                                                            m.manu_prvious_authors.map(pa => (
                                                                                pa.firstName + ' ' + pa.lastName + ', '
                                                                            ))
                                                                        }
                                                                    </>}
                                                            </> :
                                                            <>
                                                                {Object.keys(m.manu_authors).length > 0 ? m.manu_authors.map(a => (
                                                                    <> {a.author_name},</>
                                                                )) : <></>}
                                                            </>}
                                                    </span>
                                                </td>
                                                <td>
                                                    <a href={window.storage + "downloadManuscript/" + m.manu_file} target="_blank" rel="noreferrer" download={m.manu_file}>
                                                        <i style={{ fontSize: '20px' }} className="fas fa-file-download"></i>
                                                    </a>
                                                </td>
                                                <td>
                                                    {Object.keys(m.manu_se).length > 0 ? m.manu_se.map(s => (
                                                        <>
                                                            {s.se_name}
                                                            {(s.se_manu_status == 0) ?
                                                                <>
                                                                    <i className='icon-property-user-info fas fa-info-circle text-danger' title='Reversed from S.E'></i>
                                                                </> :
                                                                (s.se_manu_status == 3) ?
                                                                    <>
                                                                        <i className='icon-property-user-info fas fa-info-circle text-success' title='Processed by S.E'></i>
                                                                    </> :
                                                                    <></>}
                                                            <br />
                                                        </>
                                                    )) : <></>}
                                                </td>
                                                <td>
                                                    <i className="icon-property-info" title='View Reviewing Report' onClick={() => repModalShow(m.manu_id, m.manu_file_id, m.manu_title, m.manu_year, m.manu_pno)}>View</i>
                                                </td>

                                                <td>
                                                    <i className="icon-property-info" title='Return to M.E' onClick={() => sendToMeFromDme(m.manu_id)}>Send</i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </> :
                        <>
                            <div className='text-center'>
                                <i className='text-danger'>No Data</i>
                            </div>
                        </>}
                </>}
        </>
    )
}

export default SeProManu