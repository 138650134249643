import React from 'react'

const Footer = () => {
    return (
        <>
            <footer className="main-footer">
                <center>
                    Developed By: <a href="https://www.micrologicx.net/" target="_blank" rel="noreferrer">Micrologicx</a>
                </center>
            </footer>
        </>
    )
}

export default Footer