import React, { useState, useEffect } from 'react'
import Footer from './inc/Footer'
import Header from './inc/Header'
import Sidebar from './inc/Sidebar'
import Loading from 'react-fullscreen-loading'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

const CheckApi = () => {

    const [loader, setLoader] = useState(false)
    const [data, setData] = useState("")

    async function userData() {
        try {
            const formData = new FormData
            formData.append('list', 1)
            let result = await fetch(window.api + "getCurrentLocation", {
                method: 'POST',
                body: formData
            })
            result = await result.json()
            console.warn(result)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        userData()
    }, [])

    
    return (
        <>
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <div className="navbar-bg"></div>
                    <Header />
                    <Sidebar />
                    <div className="main-content">
                        {loader ? <><Loading loading loaderColor="#3498db" /></> : <></>}
                        <section className="section">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>All Users</h4>
                                            {/* <div className="card-header-form">
                                                <form>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Search" />
                                                        <div className="input-group-btn">
                                                            <button className="btn btn-primary"><i className="fas fa-search"></i></button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div> */}
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default CheckApi