import React, { useState, useEffect } from 'react'
import Footer from './inc/Footer'
import Header from './inc/Header'
import Sidebar from './inc/Sidebar'
import Table from 'react-bootstrap/Table'
import { useLocation } from 'react-router-dom'

const RevReport = () => {

    const location = useLocation()

    const [fetchRepLoader, setFetchRepLoader] = useState(false)
    const [repManuId, setRepManuId] = useState("")
    const [repManuFileId, setRepManuFileId] = useState("")
    const [repManuTitle, setRepManuTitle] = useState("")
    const [repManuYear, setRepManuYear] = useState("")
    const [repManuPno, setRepManuPno] = useState("")
    const [repManuData, setRepManuData] = useState([])
    const [repFileData, setRepFileData] = useState("")

    async function getRevReport(){
        const formData = new FormData
        formData.append('manu_id', 24)
        let result = await fetch(window.api + "getReviewingReport", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result) {
            setFetchRepLoader(false)
            setRepManuData(result.all_reviewing)
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
        getRevReport()
    }, [])


    return (
        <>
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <div className="navbar-bg"></div>
                    <Header />
                    <Sidebar />
                    <div className="main-content">
                        <section className="section">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Reviewing Report {location.state.manuId}</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default RevReport