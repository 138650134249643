import React, { useState, useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Loading from 'react-fullscreen-loading'

const Topics = () => {
  const userId = window.sessionStorage.getItem("id")
  const type = window.sessionStorage.getItem("type")
  const [topics, setTopics] = useState([])
  const [topic, setTopic] = useState("")
  const [keywords, setKeywords] = useState("")
  const [deadline, setDeadline] = useState("")
  const [id, setId] = useState("")
  const [savingLoader, setSavingLoader] = useState(false)
  const [loader, setLoader] = useState(false)
  const [success, setSuccess] = useState(false)
  const [successText, setSuccessText] = useState("")
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState("")

  const getTopics = async () => {
    try {
      const res = await fetch(window.api + "getAllTopicAdmin")
      const data = await res.json()
      setTopics(data.topicList)

    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    getTopics()
  }, []);

  function setEditTopic(id, topic, keywords, deadline) {
    setId(id)
    setTopic(topic)
    setKeywords(keywords)
    setDeadline(deadline)
  }

  function unSetEditTopic() {
    setId("")
    setTopic("")
    setKeywords("")
    setDeadline("")
  }

  async function save() {
    setSavingLoader(true)
    const formData = new FormData()
    formData.append('id', id)
    formData.append('topic', topic)
    formData.append('keywords', keywords)
    formData.append('deadline', deadline)
    formData.append('updated_by', userId)
    try {
      let res = await fetch(window.api + "saveTopic", {
        method: 'POST',
        body: formData,
      })

      res = await res.json()
      if (res.already) {
        setSavingLoader(false)
        setSuccess(false)
        setSuccessText("")
        setError(true)
        setErrorText("Speciality already added")
      } else if (res.success) {
        getTopics()
        setSavingLoader(false)
        setId("")
        setTopic("")
        setKeywords("")
        setDeadline("")
        setError(false)
        setErrorText("")
        setSuccess(true)
        setSuccessText("Speciality saved successfully")

      }
    } catch (err) {
      console.log(err)
    }
  }

  async function deleteTopic(id) {
    confirmAlert({
      title: 'Confirmation',
      message: 'Are you sure want to Delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => del(id)
        },
        {
          label: 'No',

        }
      ]
    })

  }

  async function del(id) {
    setLoader(true)
    const formData = new FormData()
    formData.append('id', id)

    try {
      let res = await fetch(window.api + "deleteTopic", {
        method: 'POST',
        body: formData,
      })

      res = await res.json()
      if (res.success) {
        getTopics()
        setLoader(false)
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      {loader ? <><Loading loading loaderColor="#3498db" /></> : <></>}
      <div className='row'>
        {error ?
          <>
            <div class="alert alert-danger">
              {errorText}
            </div>
          </> :
          success ?
            <>
              <div class="alert alert-success">
                {successText}
              </div>
            </> :
            <></>
        }
        <div className='col-md-12'>
          <label>Topic</label>
          <input type='text' className='form-control' value={topic} onChange={(e) => setTopic(e.target.value)} />
        </div>
        <div className='col-md-9'>
          <label>Keywords</label>
          <input type='text' className='form-control' value={keywords} onChange={(e) => setKeywords(e.target.value)} />
        </div>
        <div className='col-md-3'>
          <label>Deadline</label>
          <input type='date' className='form-control' value={deadline} onChange={(e) => setDeadline(e.target.value)} />
        </div>
        <br />
        <div className='col-md-5 offset-7'>
          <button onClick={save} className='btn btn-success btn-pad' disabled={!topic || !keywords || !deadline}>{savingLoader ? <>Saving..</> : <>{id ? 'Update' : 'Save'} </>}</button>
          {id ?
            <>
              <button onClick={unSetEditTopic} className='btn btn-danger btn-pad'>Cancel</button>
            </> :
            <></>}
        </div>
      </div>
      <hr />
      <div className='row'>
        <div className='col-md-12'>
          <table className='table table-striped table-sm'>
            <thead>
              <tr>
                <th style={{ width: '100px' }}>Date</th>
                <th>Topic/Keywords</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(topics).length > 0 ? topics.map(t => (
                (t.topic === topic && id == t.id) ?
                  <></> :
                  <tr>
                    <td>{t.deadline}</td>
                    <td>{t.topic}<br /><b>Keywords:</b>{t.keywords}</td>
                    <td>
                      <i className='far fa-edit edit-icon-i' onClick={() => setEditTopic(t.id, t.topic, t.keywords, t.deadline)}></i>
                      {(type === 'A' || type === 'SA') ?
                        <>
                          <i className='far fa-trash-alt delete-icon-i' onClick={() => deleteTopic(t.id)}></i>
                        </> :
                        <>
                        <i className='far fa-trash-alt delete-icon-i text-secondary'></i>
                        </>}

                    </td>
                  </tr>

              )) :
                <></>}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default Topics