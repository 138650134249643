import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

const Sidebar = () => {
    const location = useLocation()
    const path = location.pathname

    const type = window.sessionStorage.getItem("type")

    return (
        <>
            <div className="main-sidebar sidebar-style-2">
                <aside id="sidebar-wrapper">
                    <div className="sidebar-brand">
                        <a href="/"> <img alt="image" src="assets/img/logo.png" className="header-logo" /> <span
                            className="logo-name"></span>
                        </a>
                    </div>
                    <ul className="sidebar-menu" style={{ marginBottom: '20px' }}>
                        {/* <li className="menu-header">Main</li> */}
                        <li className={"dropdown " + (path === "/" ? "active" : "")}>
                            <NavLink to='/' className="nav-link"><i className="fas fa-laptop"></i><span>Dashboard</span></NavLink>
                        </li>
                        {(type === 'ME' || type === 'A' || type === 'SA' || type === 'EO' || type === 'P') ?
                            <>
                                {/* <li className="menu-header">Basic</li> */}
                            </> :
                            <></>}
                        {(type === 'EIC' || type === 'ME' || type === 'A' || type === 'SA' || type === 'EO' || type === 'P') ?
                            <>
                                <li className={"dropdown " + (path === "/Misc" ? "active" : "")}>
                                    <NavLink to='/Misc' className="nav-link"><i className="fas fa-align-left"></i><span>Misc Entries</span></NavLink>
                                </li>
                            </> :
                            <></>}
                        {(type === 'EIC' || type === 'ME' || type === 'A' || type === 'SA') ?
                            <>
                                <li className={"dropdown " + (path === "/ManageUser" ? "active" : "")}>
                                    <NavLink to='/ManageUser' className="nav-link"><i className="fas fa-users"></i><span>User Management</span></NavLink>
                                </li>
                            </> :
                            <></>}
                        {(type === 'EIC' || type === 'ME' || type === 'A' || type === 'SA' || type === 'P' || type === 'PI') ?
                            <>
                                <li className="menu-header">Publications</li>
                            </> :
                            <></>}
                        {(type === 'EIC' || type === 'ME' || type === 'A' || type === 'SA' || type === 'P' || type === 'PI') ?
                            <>
                                <li className={"dropdown " + (path === "/Publications" ? "active" : "")}>
                                    <NavLink to='/Publications' className="nav-link"><i className="fas fa-server"></i><span>All Publications</span></NavLink>
                                </li>
                            </> :
                            <></>}
                        {(type === 'EIC' || type === 'ME' || type === 'A' || type === 'SA' || type === 'P' || type === 'PI') ?
                            <>
                                <li className={"dropdown " + (path === "/PublishPaper" ? "active" : "")}>
                                    <NavLink to='/PublishPaper' className="nav-link"><i className="fab fa-leanpub"></i><span>Publish Paper</span></NavLink>
                                </li>
                            </> :
                            <></>}
                        {(type === 'EIC' || type === 'ME' || type === 'A' || type === 'SA' || type === 'P' || type === 'PI') ?
                            <>
                                <li className={"dropdown " + (path === "/Inpres" ? "active" : "")}>
                                    <NavLink to='/Inpres' className="nav-link"><i className="fab fa-leanpub"></i><span>Inpres Paper</span></NavLink>
                                </li>
                            </> :
                            <></>}
                        {(type === 'EIC' || type === 'ME' || type === 'A' || type === 'SA' || type === 'EO') ?
                            <>
                                <li className="menu-header">Manuscripts</li>
                            </> :
                            <></>}
                        {(type === 'ME' || type === 'A' || type === 'SA' || type === 'EO') ?
                            <>
                                <li className={"dropdown " + (path === "/Manuscripts" ? "active" : "")}>
                                    <NavLink to='/Manuscripts' className="nav-link"><i className="fas fa-clipboard-list"></i><span>All Manuscripts</span></NavLink>
                                </li>
                            </> :
                            <></>}
                        {/* {(type === 'EIC' || type === 'A' || type === 'SA') ?
                            <>
                                <li className={"dropdown " + (path === "/EICManuscripts" ? "active" : "")}>
                                    <NavLink to='/EICManuscripts' className="nav-link"><i className="fas fa-clipboard-list"></i><span>E.I.C Manuscripts</span></NavLink>
                                </li>
                            </> :
                            <></>} */}
                        {(type === 'ME' || type === 'A' || type === 'SA') ?
                            <>
                                <li className={"dropdown " + (path === "/MEManuscripts" ? "active" : "")}>
                                    <NavLink to='/MEManuscripts' className="nav-link"><i className="fas fa-clipboard-list"></i><span>M.E Manuscripts</span></NavLink>
                                </li>
                            </> :
                            <></>}
                        {/* {(type === 'DME' || type === 'A' || type === 'SA') ?
                            <>
                                <li className={"dropdown " + (path === "/DMEManuscripts" ? "active" : "")}>
                                    <NavLink to='/DMEManuscripts' className="nav-link"><i className="fas fa-clipboard-list"></i><span>D.M.E Manuscripts</span></NavLink>
                                </li>
                            </> :
                            <></>} */}
                    </ul>
                </aside>
            </div>
        </>
    )
}

export default Sidebar