import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Loading from 'react-fullscreen-loading'
import Table from 'react-bootstrap/Table'

const SeManu = () => {
    const userId = window.sessionStorage.getItem("id")

    const [seManuListLoader, setSeManuListLoader] = useState(false)

    const [seManuscript, setSeManuscript] = useState([])
    const [manuscriptAuthors, setManuscriptAuthors] = useState([])
    const [seList, setSeList] = useState([])
    const [specialityList, setSpecialityList] = useState([])

    const [authorManuId, setAuthorManuId] = useState([])
    const [authorShow, setAuthorShow] = useState(false)
    const authorModalClose = () => setAuthorShow(false)
    const authorModalShow = (authors, manuId) => {
        setAuthorManuId(manuId)
        setManuscriptAuthors(authors)
        setAuthorShow(true)
    }

    const [seLoader, setSeLoader] = useState(false)
    const [seLoaderId, setSeLoaderId] = useState("")
    const [seSpeciality, setSeSpeciality] = useState("")
    const [seName, setSeName] = useState("")
    const [seManuId, setSeManuId] = useState("")
    const [seManuCorName, setSeManuCorName] = useState("")
    const [seManuTitle, setSeManuTitle] = useState("")
    const [seManuFileId, setSeManuFileId] = useState("")
    const [seManuYear, setSeManuYear] = useState("")
    const [seManuPno, setSeManuPno] = useState("")

    const [loader, setLoader] = useState(false)

    async function manuscriptData() {
        try {
            setSeManuListLoader(true)
            let datares = await fetch(window.api + "getAllMeSeManuAdmin")
            datares = await datares.json()
            if (datares) {
                setSeManuscript(datares.manuscripts)
                setSeManuListLoader(false)
            }

        } catch (err) {
            console.log(err);
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
        manuscriptData()
    }, [])

    const [seShow, setSeShow] = useState(false)
    const seModalClose = () => {
        setSeShow(false)
        setSeManuId("")
        setSeManuCorName("")
        setSeManuTitle("")
        setSeManuFileId("")
        setSeManuYear("")
        setSeManuPno("")
        setSeSpeciality("")
        setSeList([])
        setSeLoaderId("")
        setSeName("")
    }
    const seModalShow = async (manuId, manuCorName, manuTitle, manuFileId, manuYear, manuPno) => {
        setSeShow(true)
        setSeManuId(manuId)
        setSeManuCorName(manuCorName)
        setSeManuTitle(manuTitle)
        setSeManuFileId(manuFileId)
        setSeManuYear(manuYear)
        setSeManuPno(manuPno)
        let specRes = await fetch(window.api + "getSpeciality")
        specRes = await specRes.json()
        setSpecialityList(specRes.specialityList)
    }

    async function searchSeById(id) {
        setSeList([])
        setSeLoader(true)
        setSeSpeciality(id)
        const formData = new FormData()
        formData.append('spec_id', id)
        try {
            let seRes = await fetch(window.api + "searchSeBySpecId", {
                method: 'POST',
                body: formData,
            })

            seRes = await seRes.json()
            if (seRes) {
                setSeLoader(false)
                setSeList(seRes.seList)
            }

        } catch (err) {
            console.log(err)
        }
    }

    async function searchSeByName(name) {
        setSeList([])
        setSeLoader(true)
        setSeName(name)
        const formData = new FormData()
        formData.append('name', name)
        formData.append('spec_id', seSpeciality)
        try {
            let seRes = await fetch(window.api + "searchSeByName", {
                method: 'POST',
                body: formData,
            })

            seRes = await seRes.json()
            if (seRes) {
                setSeLoader(false)
                setSeList(seRes.seList)
            }

        } catch (err) {
            console.log(err)
        }
    }

    async function sendToSe(seId, seEmail, seName) {
        setSeLoader(true)
        setSeLoaderId(seId)
        const formData = new FormData()
        formData.append('se_manu_id', seManuId)
        formData.append('se_manu_cor_name', seManuCorName)
        formData.append('se_manu_title', seManuTitle)
        formData.append('se_manu_file_id', seManuFileId)
        formData.append('se_manu_year', seManuYear)
        formData.append('se_manu_pno', seManuPno)
        formData.append('se_id', seId)
        formData.append('se_email', seEmail)
        formData.append('se_name', seName)
        formData.append('updated_by', userId)

        try {
            let sendData = await fetch(window.api + "sendToSe", {
                method: 'POST',
                body: formData,
            })

            sendData = await sendData.json()
            if (sendData.success) {
                setSeLoader(false)
                manuscriptData()
                seModalClose()
            }
        } catch (err) {
            console.log(err)
        }
    }

    function reverseFromSe(seAssignId, seManuId, seEmail, seName, seManuYear, seManuPno, seManuTitle) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure want to reverse?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Reverse(seAssignId, seManuId, seEmail, seName, seManuYear, seManuPno, seManuTitle)
                },
                {
                    label: 'No',

                }
            ]
        })
    }

    async function Reverse(seAssignId, seManuId, seEmail, seName, seManuYear, seManuPno, seManuTitle) {
        setLoader(true)
        const formData = new FormData()
        formData.append('se_assign_id', seAssignId)
        formData.append('se_manu_id', seManuId)
        formData.append('se_email', seEmail)
        formData.append('se_name', seName)
        formData.append('se_manu_year', seManuYear)
        formData.append('se_manu_pno', seManuPno)
        formData.append('se_manu_title', seManuTitle)
        formData.append('updated_by', userId)

        try {
            let reverseData = await fetch(window.api + "reverseFromSe", {
                method: 'POST',
                body: formData,
            })

            reverseData = await reverseData.json()
            if (reverseData.success) {
                setLoader(false)
                manuscriptData()
            }
        } catch (err) {
            console.log(err)
        }
    }


    const [fetchRepLoader, setFetchRepLoader] = useState(false)
    const [repManuId, setRepManuId] = useState("")
    const [repManuFileId, setRepManuFileId] = useState("")
    const [repManuTitle, setRepManuTitle] = useState("")
    const [repManuYear, setRepManuYear] = useState("")
    const [repManuPno, setRepManuPno] = useState("")
    const [repManuData, setRepManuData] = useState([])
    const [repShow, setRepShow] = useState(false)
    const repModalClose = () => {
        setRepShow(false)
        setRepManuId("")
        setRepManuFileId("")
        setRepManuTitle("")
        setRepManuYear("")
        setRepManuPno("")
        setRepManuData([])
    }
    const repModalShow = async (manuId, manuFileId, manuTitle, manuYear, manuPno) => {

        setFetchRepLoader(true)
        setRepShow(true)
        setRepManuId(manuId)
        setRepManuFileId(manuFileId)
        setRepManuTitle(manuTitle)
        setRepManuYear(manuYear)
        setRepManuPno(manuPno)

        const formData = new FormData
        formData.append('manu_id', manuId)
        let result = await fetch(window.api + "getReviewingReport", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result) {
            setFetchRepLoader(false)
            setRepManuData(result.all_reviewing)
        }
    }

    //Reminder Code
    const [remRevId, setRemRevId] = useState("")
    const [remRevManuId, setRemRevManuId] = useState("")
    const [remRevManuFileId, setRemRevManuFileId] = useState("")
    const [reminderLoader, setReminderLoader] = useState(false)

    const [reminderList, setReminderList] = useState([])

    const [reminderShow, setReminderShow] = useState(false)
    const reminderModalClose = () => {
        setRemRevId("")
        setRemRevManuId("")
        setRemRevManuFileId("")
        setReminderShow(false)
    }
    const reminderModalShow = async (manuId, ManuFileId, revId) => {

        setRemRevId(revId)
        setRemRevManuId(manuId)
        setRemRevManuFileId(ManuFileId)
        setReminderShow(true)
        getReminderList(manuId, ManuFileId, revId)
    }

    async function getReminderList(manuId, ManuFileId, revId) {
        setReminderLoader(true)
        const formData = new FormData
        formData.append('rem_manu_id', manuId)
        formData.append('rem_manu_file_id', ManuFileId)
        formData.append('rem_rev_id', revId)

        try {
            let remData = await fetch(window.api + "reminderList", {
                method: 'POST',
                body: formData,
            })
            remData = await remData.json()
            if (remData.reminders) {
                setReminderLoader(false)
                setReminderList(remData.reminders)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const [sendRemLoader, setSendRemLoader] = useState(false)

    async function sendReminder() {

        setSendRemLoader(true)
        const formData = new FormData
        formData.append('rem_manu_id', remRevManuId)
        formData.append('rem_manu_file_id', remRevManuFileId)
        formData.append('rem_rev_id', remRevId)
        formData.append('rem_type', 'remSe')

        try {
            let remData = await fetch(window.api + "sendReminder", {
                method: 'POST',
                body: formData,
            })
            remData = await remData.json()
            if (remData.success) {
                setSendRemLoader(false)
                getReminderList(remRevManuId, remRevManuFileId, remRevId)
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            {loader ? <><Loading loading loaderColor="#22C7B8" /></> : <></>}
            <Modal show={authorShow} onHide={authorModalClose}>
                <Modal.Header>
                    <Modal.Title>Author Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <table className='table table-striped'>
                                <thead>
                                    <th>Name</th>
                                    <th>Affiliation</th>
                                </thead>
                                {(authorManuId < 14245) ?
                                    <>
                                        {Object.keys(manuscriptAuthors).length > 0 ? manuscriptAuthors.map(ad => (
                                            <tbody>
                                                <tr>
                                                    <td>{ad.firstName + ' ' + ad.lastName} </td>
                                                    <td>{ad.affiliation}</td>
                                                </tr>
                                            </tbody>
                                        )) : <></>}
                                    </> :
                                    <>
                                        {Object.keys(manuscriptAuthors).length > 0 ? manuscriptAuthors.map(ad => (
                                            <tbody>
                                                <tr>
                                                    <td>{ad.author_name}</td>
                                                    <td>{ad.author_affiliation}</td>
                                                </tr>
                                            </tbody>
                                        )) : <></>}
                                    </>}
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={authorModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={seShow} size="lg" onHide={seModalClose}>
                <Modal.Header>
                    <Modal.Title>Send Manuscript to Section Editor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className='row'>
                                <div className='col-md-4 p-1'>
                                    <select value={seSpeciality} onChange={(e) => searchSeById(e.target.value)} className='form-control'>
                                        <option value="">--Select Speciality--</option>
                                        {Object.keys(specialityList).length > 0 ? specialityList.map(s => (
                                            <option value={s.id}>{s.name}</option>
                                        )) : <></>}
                                    </select>
                                </div>
                                <div className='col-md-6 p-1'>
                                    <input type="text" value={seName} onChange={(e) => searchSeByName(e.target.value)} className='form-control' placeholder='Search Section Editor by name' />
                                </div>
                                <div className='col-md-2 p-1'>

                                </div>
                            </div>
                            <div className='row'>
                                <table className='table table-striped table-sm'>

                                    {Object.keys(seList).length > 0 ?
                                        <>
                                            <thead>
                                                <tr>
                                                    <th>Name / Email</th>
                                                    <th>Affiliation</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {seList.map(s =>
                                                    <tr>
                                                        <td>{s.fname} {s.lname}<br />{s.email}</td>
                                                        <td>{s.affiliation}</td>
                                                        <td>
                                                            <button onClick={() => sendToSe(s.id, s.email, s.fname)} className='btn btn-success btn-sm' disabled={seLoader || seLoaderId}>
                                                                {(seLoader && seLoaderId == s.id) ?
                                                                    'Sending..' : 'send'
                                                                }
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </> :
                                        <><table className='table text-center text-danger'><tr><td>No Data</td></tr></table></>
                                    }

                                </table>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={seModalClose} disabled={seLoader || seLoaderId}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={repShow} size="xl" onHide={repModalClose}>
                <Modal.Header>
                    <Modal.Title>Reviewing Report for Manuscript</Modal.Title>
                    <Button variant="danger" onClick={repModalClose}>Close</Button>
                </Modal.Header>
                <Modal.Body scrollable={true}>
                    <div className="scrollbar" id="style-1">
                        <div className="container-fluid">
                            {(fetchRepLoader) ?
                                <>
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <i className="text-info">Fetching Record Please Wait..</i>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div className="row">
                                        <div className="col-md-2 col-2 p-0">

                                            <img alt="image" src="assets/img/logo.png" width="100px" />

                                        </div>
                                        <div className="col-md-8 col-8 p-0 report-heading">
                                            <h2>Pakistan Journal of Agricultural Sciences</h2>
                                        </div>
                                        <div className="col-md-2 col-2 p-0">

                                            {(repManuYear != 0 && repManuPno != 0) ?
                                                <>
                                                    <h5>PJAS-{repManuYear}-{repManuPno}</h5>
                                                </> :
                                                <></>}
                                        </div>


                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <center>
                                                <h4>{repManuTitle}</h4>
                                            </center>
                                        </div>
                                    </div>

                                    {Object.keys(repManuData).length > 0 ?
                                        <>
                                            {repManuData.map((m, index) => {
                                                return (
                                                    <>
                                                        <Table bordered size="sm" className='border border-dark'>
                                                            <tbody>
                                                                <tr>
                                                                    <td><b>Manu File</b></td>
                                                                    <td>
                                                                        {(m.manu_file != null) ?
                                                                            <a href={window.storage + "downloadManuscript/" + m.manu_file} download={m.manu_file} rel="noreferrer" target="_blank">
                                                                                <i className="fas fa-file-download"></i>Manu File
                                                                            </a>
                                                                            : <i>No File</i>}
                                                                        {(m.file_status == 2) ?
                                                                            <>
                                                                                <span class="text-success"> (Eligible to Proceed)</span>
                                                                            </> :
                                                                            <></>}
                                                                    </td>
                                                                    <td><b>Supplementry File</b></td>
                                                                    <td>
                                                                        {(m.suplementry_file != null) ?
                                                                            <a href={window.storage + "downloadSuplementry/" + m.suplementry_file} download={m.suplementry_file} rel="noreferrer" target="_blank">
                                                                                <i className="fas fa-file-download"></i>Suplementry File
                                                                            </a> :
                                                                            <i className='text-info'>No File</i>}
                                                                    </td>
                                                                    <td><b>Cover File</b></td>
                                                                    <td>
                                                                        {(m.cover_file != null) ?
                                                                            <a href={window.storage + "downloadCover/" + m.cover_file} download={m.cover_file} rel="noreferrer" target="_blank">
                                                                                <i className="fas fa-file-download"></i>Cover File
                                                                            </a> :
                                                                            <i className='text-info'>No File</i>}
                                                                    </td>
                                                                </tr>
                                                                {(m.me_comments == null || m.me_comments == "") ?
                                                                    <></> :
                                                                    <>
                                                                        <tr>
                                                                            <td style={{ width: '12%' }}>Managing Editor</td>
                                                                            <td colSpan={5}>
                                                                                {(m.me_file != null) ?
                                                                                    <a href={window.storage + "downloadReply/" + m.me_file} download={m.me_file} rel="noreferrer" target="_blank">
                                                                                        <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reply File
                                                                                    </a> : <a></a>}<br />
                                                                                <b>Comments:</b> {m.me_comments}
                                                                            </td>
                                                                        </tr>
                                                                    </>}
                                                                {(m.ret_se_comments == null || m.ret_se_comments == "") ?
                                                                    <></> :
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={6}>
                                                                                <center><b>Comments to Author</b></center>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '12%' }}>Section Editor</td>
                                                                            <td colSpan={5}>
                                                                                {(m.ret_se_file != null) ?
                                                                                    <a href={window.storage + "downloadReply/" + m.ret_se_file} download={m.ret_se_file} rel="noreferrer" target="_blank">
                                                                                        <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reply File
                                                                                    </a> : <a></a>}<br />
                                                                                <b>Comments:</b> {m.ret_se_comments}
                                                                            </td>
                                                                        </tr>
                                                                    </>}
                                                                {(m.edi_comments == null || m.edi_comments == "") ?
                                                                    <></> :
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={6}>
                                                                                <center><b>Comments to Author</b></center>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '12%' }}>Editorial Office</td>
                                                                            <td colSpan={5}>
                                                                                {(m.edi_file != null) ?
                                                                                    <a href={window.storage + "downloadReply/" + m.edi_file} download={m.edi_file} rel="noreferrer" target="_blank">
                                                                                        <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reply File
                                                                                    </a> : <a></a>}<br />
                                                                                <b>Comments:</b> {m.edi_comments}
                                                                            </td>
                                                                        </tr>
                                                                    </>}
                                                                {(m.se_accept == null || m.se_accept == "") ?
                                                                    <></> :
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={6}>
                                                                                <center><b>Comments to M.E</b></center>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '12%' }}>Section Editor</td>
                                                                            <td colSpan={5}>
                                                                                {(m.se_file != null) ?
                                                                                    <>
                                                                                        <a href={window.storage + "downloadReply/" + m.se_file} download={m.se_file} rel="noreferrer" target="_blank">
                                                                                            <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reply File
                                                                                        </a><br /></> : <></>}
                                                                                <b>Accept Paper? </b>({m.se_accept})<br />
                                                                                <b>Comments:</b> {m.se_comments}
                                                                            </td>
                                                                        </tr>
                                                                    </>}
                                                                {Object.keys(m.assign_data).length > 0 ?
                                                                    <>
                                                                        <tr>
                                                                            <th style={{ width: '9%' }}>Reviewed By</th>
                                                                            <th colSpan={5}><center>Decision</center></th>
                                                                        </tr>
                                                                        {m.assign_data.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td>
                                                                                            {e.rev_fname} {(e.rev_lname !== 's') ? e.rev_lname : ''}<br />
                                                                                            {e.rev_affiliation}<br />
                                                                                            {e.created_at ? e.created_at.substr(0, 10) : <></>}
                                                                                        </td>
                                                                                        <td colSpan={5}>
                                                                                            {(e.assign_status == 0) ?
                                                                                                <><i className="text-danger">Reviewer Rejected Manuscript</i></> :
                                                                                                (e.assign_status == 1) ?
                                                                                                    <><i className="text-warning">Pending for Acceptance</i></> :
                                                                                                    (e.assign_status == 2) ?
                                                                                                        <><i className="text-info">Decision Pending</i></> :
                                                                                                        (e.assign_status == 3) ?
                                                                                                            <>
                                                                                                                <div className="row">
                                                                                                                    <div className="col-sm-6"><b>Do you want get credit for reviewing this manuscript?</b> ({e.paid})</div>
                                                                                                                    <div className="col-sm-6"><b>Subjects and contents are suitable for publications?</b> ({e.q1})</div>
                                                                                                                    <div className="col-sm-6"><b>Novelty of results is appropriate?</b> ({e.q2})</div>
                                                                                                                    <div className="col-sm-6"><b>Conclusions are well proven?</b> ({e.q3})</div>
                                                                                                                    <div className="col-sm-6"><b>Title is suitable?</b> ({e.q4})</div>
                                                                                                                    <div className="col-sm-6"><b>Number and quality of references are appropriate?</b> ({e.q5})</div>
                                                                                                                    <div className="col-sm-6"><b>The linguistic quality of paper is good?</b> ({e.q6})</div>
                                                                                                                    <div className="col-sm-6"><b>Reference is provided where data are reported?</b> ({e.q7})</div>
                                                                                                                </div>
                                                                                                                <hr />
                                                                                                                <div className="row">
                                                                                                                    <div className="col-md-6">
                                                                                                                        <b>Recomendations:  </b>
                                                                                                                        {
                                                                                                                            (e.r === 'r1') ?
                                                                                                                                <>
                                                                                                                                    <span>Accept in Present Form</span>
                                                                                                                                </> :
                                                                                                                                (e.r === 'r2') ?
                                                                                                                                    <>
                                                                                                                                        <span>Consider After Minor Revision</span>
                                                                                                                                    </> :
                                                                                                                                    (e.r === 'r3') ?
                                                                                                                                        <>
                                                                                                                                            <span>Reassess After Major Revision</span>
                                                                                                                                        </> :
                                                                                                                                        (e.r === 'r4') ?
                                                                                                                                            <>
                                                                                                                                                <span>Reject</span>
                                                                                                                                            </> :
                                                                                                                                            <></>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className="col-md-6">
                                                                                                                        {(e.reply_file_path != null) ?
                                                                                                                            <a href={window.storage + "downloadRevReply/" + e.reply_file_path} download={e.reply_file_path} rel="noreferrer" target="_blank" style={{ color: '#000000' }}>
                                                                                                                                <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reviewer Reply File
                                                                                                                            </a> : <a></a>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <hr />
                                                                                                                <div className="row">
                                                                                                                    <div className="col-sm-12">
                                                                                                                        <b>Confidential Comments to Executive Editor: </b>{e.ex_co}
                                                                                                                    </div>
                                                                                                                    <div className="col-sm-12">
                                                                                                                        <b>Comments to the Author: </b>{e.aut_co}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </> :
                                                                                                            <><i>Not Replied Yet</i></>}
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </> :
                                                                    <><tr><td>No Data</td></tr></>}
                                                            </tbody>
                                                        </Table>
                                                        {/* <hr /> */}
                                                    </>
                                                )
                                            })}
                                        </> :
                                        <><tr><td>No Data</td></tr></>}

                                </>}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={reminderShow} onHide={reminderModalClose}>
                <Modal.Header>
                    <Modal.Title>All Reminders</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            {(reminderLoader) ?
                                <>
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <i className="text-info">Fetching Record Please Wait..</i>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    {Object.keys(reminderList).length > 0 ?
                                        <table className='table table-striped'>
                                            <thead>
                                                <th>Date</th>
                                                <th>Status</th>
                                            </thead>
                                            <tbody>
                                                {reminderList.map((rl, index) => (
                                                    <tr key={index}>
                                                        <td>{rl.created_at.substr(0, 10)}</td>
                                                        <td>
                                                            Sent Successfully as {(rl.type == 'remRev') ? ' Reviewer' : ' Section Editor'}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table> :
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <i className="text-danger">No Reminder</i>
                                            </div>
                                        </div>}
                                </>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={reminderModalClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={sendReminder}>
                        {sendRemLoader ? 'Sending...' : 'Send Reminder'}
                    </Button>
                </Modal.Footer>
            </Modal>
            {(seManuListLoader) ?
                <><i>Fetching Record Please Wait.....</i></> :
                <>
                    {Object.keys(seManuscript).length > 0 ?
                        <>
                            <div className="table-responsive">
                                <table className="table table-sm table-striped table-bordered se-manu">
                                    <thead>
                                        <tr>
                                            <th>Sr#</th>
                                            <th>Title / Author</th>
                                            <th>File</th>
                                            <th>S.E List</th>
                                            <th>S.E</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {seManuscript.map(m => (
                                            <tr key={m}>
                                                <td>
                                                    {(m.manu_year == 0 && m.manu_pno == 0) ? m.manu_id : <>{"PJAS-" + m.manu_year + "-" + m.manu_pno}</>}<br />
                                                    {m.manu_date.substr(0, 10)}
                                                </td>
                                                <td>
                                                    {m.manu_title}<br />
                                                    {(m.manu_id < 14245) ?
                                                        <>
                                                            {(m.manu_prvious_authors === null) ?
                                                                <>
                                                                </> :
                                                                <>
                                                                    <span className="icon-hand" onClick={() => authorModalShow(m.manu_prvious_authors, m.manu_id)}><i className='fas fa-user'></i>
                                                                        {m.manu_prvious_authors.map(pa => (
                                                                            pa.firstName + ' ' + pa.lastName + ', '
                                                                        ))}
                                                                    </span>
                                                                </>}
                                                        </> :
                                                        <>
                                                            <span className="icon-hand" onClick={() => authorModalShow(m.manu_authors, m.manu_id)}><i className='fas fa-user'></i>
                                                                {Object.keys(m.manu_authors).length > 0 ? m.manu_authors.map(a => (
                                                                    <> {a.author_name},</>
                                                                )) : <></>}
                                                            </span>
                                                        </>}
                                                </td>
                                                <td>
                                                    {(m.manu_file_id > 16100) ?
                                                        <>
                                                            <a href={window.storage + "downloadManuscript/" + m.manu_file} target="_blank" rel="noreferrer" download={m.manu_file}>
                                                                <i style={{ fontSize: '20px' }} className="fas fa-file-download"></i>
                                                            </a>
                                                        </> :
                                                        <>
                                                            {(m.manu_file) ?
                                                                <>
                                                                    <a href={window.storage + "downloadManuscript/" + m.manu_file.substring(20)} target="_blank" rel="noreferrer" download={m.manu_file}>
                                                                        <i style={{ fontSize: '20px' }} className="fas fa-file-download"></i>
                                                                    </a>
                                                                </> :
                                                                <></>}
                                                        </>}
                                                </td>
                                                <td>
                                                    {Object.keys(m.manu_se).length > 0 ? m.manu_se.map(s => (
                                                        <>
                                                            <i
                                                                className="p-1"
                                                                onClick={() => repModalShow(m.manu_id, m.manu_file_id, m.manu_title, m.manu_year, m.manu_pno)}
                                                            >{(s.se_manu_status == 0) ? <del className='icon-property-user-cancle' title='Reversed from S.E'>{s.se_name}</del> : <span className='icon-property-user-info'  title='In Process'>{s.se_name}</span>}</i>
                                                            {(s.se_manu_status == 1) ?
                                                                <>
                                                                    <i
                                                                        title='Reverse from S.E'
                                                                        onClick={() => reverseFromSe(s.se_assign_id, s.se_manu_id, s.se_email, s.se_name, m.manu_year, m.manu_pno, m.manu_title)}
                                                                        className='icon-property-user-cancle far fa-window-close'></i>
                                                                </> :
                                                                (s.se_manu_status == 2) ?
                                                                    <>
                                                                        <i
                                                                            className="icon-property-user-info fas fa-mobile-alt p-1 "
                                                                            title="Reminder"
                                                                            onClick={() => reminderModalShow(m.manu_id, m.manu_file_id, s.se_id)}></i>
                                                                    </> :
                                                                    (s.se_manu_status == 3) ?
                                                                        <>
                                                                            <i
                                                                                className='icon-property-user-info fas fa-info-circle text-success'
                                                                                onClick={() => repModalShow(m.manu_id, m.manu_file_id, m.manu_title, m.manu_year, m.manu_pno)}
                                                                                title='Processed by S.E'></i>
                                                                        </> :
                                                                        <></>}
                                                            <br />
                                                        </>
                                                    )) : <></>}
                                                </td>
                                                <td>
                                                    {
                                                        (m.manu_status == 5) ?
                                                            <>
                                                                <i title='Send to S.E' className="icon-property fas fa-share" onClick={() => seModalShow(m.manu_id, m.manu_cor_author_name, m.manu_title, m.manu_file_id, m.manu_year, m.manu_pno)}></i>
                                                            </> :
                                                            <>
                                                                <i className='text-info'>In process</i>
                                                            </>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </> :
                        <>
                            <div className='text-center'>
                                <i className='text-danger'>No Data</i>
                            </div>
                        </>}

                </>}

        </>
    )
}

export default SeManu