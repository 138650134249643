import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Loading from 'react-fullscreen-loading'

const SeManu = () => {
    const userId = window.sessionStorage.getItem("id")

    const [seManuListLoader, setSeManuListLoader] = useState(false)

    const [seManuscript, setSeManuscript] = useState([])
    const [manuscriptAuthors, setManuscriptAuthors] = useState([])
    const [seList, setSeList] = useState([])
    const [specialityList, setSpecialityList] = useState([])

    const [authorShow, setAuthorShow] = useState(false)
    const authorModalClose = () => setAuthorShow(false)
    const authorModalShow = (authors) => {
        setManuscriptAuthors(authors)
        setAuthorShow(true)
    }

    const [seLoader, setSeLoader] = useState(false)
    const [seLoaderId, setSeLoaderId] = useState("")
    const [seSpeciality, setSeSpeciality] = useState("")
    const [seName, setSeName] = useState("")
    const [seManuId, setSeManuId] = useState("")
    const [seManuCorName, setSeManuCorName] = useState("")
    const [seManuTitle, setSeManuTitle] = useState("")
    const [seManuFileId, setSeManuFileId] = useState("")
    const [seManuYear, setSeManuYear] = useState("")
    const [seManuPno, setSeManuPno] = useState("")

    const [loader, setLoader] = useState(false)

    async function manuscriptData() {
        try {
            setSeManuListLoader(true)
            let datares = await fetch(window.api + "getAllDmeSeManuAdmin")
            datares = await datares.json()
            if (datares) {
                setSeManuscript(datares.manuscripts)
                setSeManuListLoader(false)
            }

        } catch (err) {
            console.log(err);
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
        manuscriptData()
    }, [])

    const [seShow, setSeShow] = useState(false)
    const seModalClose = () => {
        setSeShow(false)
        setSeManuId("")
        setSeManuCorName("")
        setSeManuTitle("")
        setSeManuFileId("")
        setSeManuYear("")
        setSeManuPno("")
        setSeSpeciality("")
        setSeList([])
        setSeLoaderId("")
        setSeName("")
    }
    const seModalShow = async (manuId, manuCorName, manuTitle, manuFileId, manuYear, manuPno) => {
        setSeShow(true)
        setSeManuId(manuId)
        setSeManuCorName(manuCorName)
        setSeManuTitle(manuTitle)
        setSeManuFileId(manuFileId)
        setSeManuYear(manuYear)
        setSeManuPno(manuPno)
        let specRes = await fetch(window.api + "getSpeciality")
        specRes = await specRes.json()
        setSpecialityList(specRes.specialityList)
    }

    async function searchSeByName(name) {
        setSeList([])
        setSeLoader(true)
        setSeName(name)
        const formData = new FormData()
        formData.append('name', name)
        formData.append('spec_id', seSpeciality)
        try {
            let seRes = await fetch(window.api + "searchSeByName", {
                method: 'POST',
                body: formData,
            })

            seRes = await seRes.json()
            if (seRes) {
                setSeLoader(false)
                setSeList(seRes.seList)
            }

        } catch (err) {
            console.log(err)
        }
    }

    async function sendToSe(seId, seEmail, seName) {
        setSeLoader(true)
        setSeLoaderId(seId)
        const formData = new FormData()
        formData.append('se_manu_id', seManuId)
        formData.append('se_manu_cor_name', seManuCorName)
        formData.append('se_manu_title', seManuTitle)
        formData.append('se_manu_file_id', seManuFileId)
        formData.append('se_manu_year', seManuYear)
        formData.append('se_manu_pno', seManuPno)
        formData.append('se_id', seId)
        formData.append('se_email', seEmail)
        formData.append('se_name', seName)
        formData.append('updated_by', userId)

        try {
            let sendData = await fetch(window.api + "sendToSe", {
                method: 'POST',
                body: formData,
            })

            sendData = await sendData.json()
            if (sendData.success) {
                setSeLoader(false)
                manuscriptData()
                seModalClose()
            }
        } catch (err) {
            console.log(err)
        }
    }

    function reverseFromSe(seAssignId, seManuId, seEmail, seName, seManuYear, seManuPno, seManuTitle) {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure want to reverse?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => Reverse(seAssignId, seManuId, seEmail, seName, seManuYear, seManuPno, seManuTitle)
                },
                {
                    label: 'No',

                }
            ]
        })
    }

    async function Reverse(seAssignId, seManuId, seEmail, seName, seManuYear, seManuPno, seManuTitle) {
        setLoader(true)
        const formData = new FormData()
        formData.append('se_assign_id', seAssignId)
        formData.append('se_manu_id', seManuId)
        formData.append('se_email', seEmail)
        formData.append('se_name', seName)
        formData.append('se_manu_year', seManuYear)
        formData.append('se_manu_pno', seManuPno)
        formData.append('se_manu_title', seManuTitle)
        formData.append('updated_by', userId)

        try {
            let reverseData = await fetch(window.api + "reverseFromSe", {
                method: 'POST',
                body: formData,
            })

            reverseData = await reverseData.json()
            if (reverseData.success) {
                setLoader(false)
                manuscriptData()
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            {loader ? <><Loading loading loaderColor="#22C7B8" /></> : <></>}
            <Modal show={authorShow} onHide={authorModalClose}>
                <Modal.Header>
                    <Modal.Title>Author Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <table className='table table-striped'>
                                <thead>
                                    <th>Name</th>
                                    <th>Affiliation</th>
                                </thead>
                                {Object.keys(manuscriptAuthors).length > 0 ? manuscriptAuthors.map(ad => (
                                    <tbody>
                                        <tr>
                                            <td>{ad.author_name}</td>
                                            <td>{ad.author_affiliation}</td>
                                        </tr>
                                    </tbody>
                                )) : <></>}
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={authorModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={seShow} size="lg" onHide={seModalClose}>
                <Modal.Header>
                    <Modal.Title>Send Manuscript to Section Editor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className='row'>
                                <div className='col-md-4 p-1'>
                                    <select value={seSpeciality} onChange={(e) => setSeSpeciality(e.target.value)} className='form-control'>
                                        <option value="">--Select Speciality--</option>
                                        {Object.keys(specialityList).length > 0 ? specialityList.map(s => (
                                            <option value={s.id}>{s.name}</option>
                                        )) : <></>}
                                    </select>
                                </div>
                                <div className='col-md-6 p-1'>
                                    <input type="text" value={seName} onChange={(e) => searchSeByName(e.target.value)} className='form-control' placeholder='Search Section Editor by name' />
                                </div>
                                <div className='col-md-2 p-1'>

                                </div>
                            </div>
                            <div className='row'>
                                <table className='table table-striped table-sm'>

                                    {Object.keys(seList).length > 0 ?
                                        <>
                                            <thead>
                                                <tr>
                                                    <th>Name / Email</th>
                                                    <th>Affiliation</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {seList.map(s =>
                                                    <tr>
                                                        <td>{s.fname} {s.lname}<br />{s.email}</td>
                                                        <td>{s.affiliation}</td>
                                                        <td>
                                                            <button onClick={() => sendToSe(s.id, s.email, s.fname)} className='btn btn-success btn-sm' disabled={seLoader || seLoaderId}>
                                                                {(seLoader && seLoaderId == s.id) ?
                                                                    'Sending..' : 'send'
                                                                }
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </> :
                                        <><table className='table text-center text-danger'><tr><td>No Data</td></tr></table></>
                                    }

                                </table>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={seModalClose} disabled={seLoader || seLoaderId}>
                        Cancle
                    </Button>
                </Modal.Footer>
            </Modal>
            {(seManuListLoader) ?
                <><i>Fetching Record Please Wait.....</i></> :
                <>
                    {Object.keys(seManuscript).length > 0 ?
                        <>
                            <div className="table-responsive">
                                <table className="table table-sm table-striped table-bordered se-manu">
                                    <thead>
                                        <tr>
                                            <th>Sr#</th>
                                            <th>Title / Author</th>
                                            <th>File</th>
                                            <th>S.E List</th>
                                            <th>S.E</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {seManuscript.map(m => (
                                            <tr key={m}>
                                                <td>
                                                    {(m.manu_year == 0 && m.manu_pno == 0) ? m.manu_id : <>{"PJAS-" + m.manu_year + "-" + m.manu_pno}</>}<br />
                                                    {m.manu_date.substr(0, 10)}
                                                </td>
                                                <td>
                                                    {m.manu_title}<br />
                                                    <span className="icon-hand" onClick={() => authorModalShow(m.manu_authors)}><i className='fas fa-user'></i>
                                                        {(m.manu_id < 14245) ?
                                                            <>
                                                                {(m.manu_prvious_authors === null) ?
                                                                    <>
                                                                    </> :
                                                                    <>
                                                                        {
                                                                            m.manu_prvious_authors.map(pa => (
                                                                                pa.firstName + ' ' + pa.lastName + ', '
                                                                            ))
                                                                        }
                                                                    </>}
                                                            </> :
                                                            <>
                                                                {Object.keys(m.manu_authors).length > 0 ? m.manu_authors.map(a => (
                                                                    <> {a.author_name},</>
                                                                )) : <></>}
                                                            </>}
                                                    </span>
                                                </td>
                                                <td>
                                                    <a href={window.storage + "downloadManuscript/" + m.manu_file} target="_blank" rel="noreferrer" download={m.manu_file}>
                                                        <i style={{ fontSize: '20px' }} className="fas fa-file-download"></i>
                                                    </a>
                                                </td>
                                                <td>
                                                    {Object.keys(m.manu_se).length > 0 ? m.manu_se.map(s => (
                                                        <>
                                                            {s.se_name}
                                                            {(s.se_manu_status == 0) ?
                                                                <>
                                                                    <i className='icon-property-user-info fas fa-info-circle text-danger' title='Reversed from S.E'></i>
                                                                </> :
                                                                (s.se_manu_status == 1) ?
                                                                    <>
                                                                        <i title='Reverse from S.E' onClick={() => reverseFromSe(s.se_assign_id, s.se_manu_id, s.se_email, s.se_name, m.manu_year, m.manu_pno, m.manu_title)} className='icon-property-user-cancle far fa-window-close'></i>
                                                                    </> :
                                                                    (s.se_manu_status == 2) ?
                                                                        <>
                                                                            <i className='icon-property-user-info fas fa-info-circle' title='In Process by S.E'></i>
                                                                        </> :
                                                                        (s.se_manu_status == 3) ?
                                                                            <>
                                                                                <i className='icon-property-user-info fas fa-info-circle text-success' title='Processed by S.E'></i>
                                                                            </> :
                                                                            <></>}
                                                            <br />
                                                        </>
                                                    )) : <></>}
                                                </td>
                                                <td>
                                                    {
                                                        (m.manu_status == 5) ?
                                                            <>
                                                                <i title='Send to S.E' className="icon-property fas fa-share" onClick={() => seModalShow(m.manu_id, m.manu_cor_author_name, m.manu_title, m.manu_file_id, m.manu_year, m.manu_pno)}></i>
                                                            </> :
                                                            <>
                                                                <i className='text-info'>In process</i>
                                                            </>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </> :
                        <>
                            <div className='text-center'>
                                <i className='text-danger'>No Data</i>
                            </div>
                        </>}

                </>}

        </>
    )
}

export default SeManu