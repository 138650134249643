import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Badge from 'react-bootstrap/Badge'
import Loading from 'react-fullscreen-loading'
import Table from 'react-bootstrap/Table'
import Collapse from 'react-bootstrap/Collapse'

const Manu = () => {

    const userId = window.sessionStorage.getItem("id")

    const [manuListLoader, setManuListLoader] = useState(false)

    const [manuscript, setManuscript] = useState([])
    const [manuscriptAuthors, setManuscriptAuthors] = useState([])
    const [specialityList, setSpecialityList] = useState([])
    const [revList, setRevList] = useState([])


    const [loader, setLoader] = useState(false)

    const [authorShow, setAuthorShow] = useState(false)
    const authorModalClose = () => setAuthorShow(false)
    const authorModalShow = (authors) => {
        setManuscriptAuthors(authors)
        setAuthorShow(true)
    }

    async function manuscriptData() {
        try {
            setManuListLoader(true)
            let datares = await fetch(window.api + "getAllEicManuAdmin")
            datares = await datares.json()
            if (datares) {
                setManuscript(datares.manuscripts)
                setManuListLoader(false)
            }
        } catch (err) {
            console.log(err);
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
        manuscriptData()
    }, [])

    const [open, setOpen] = useState(manuscript.map(() => false))
    useEffect(() => {
        if (manuscript.length !== open.length) {
            setOpen(manuscript.map(
                () => false))
        }
    }, [manuscript, open])

    const [revLoader, setRevLoader] = useState(false)
    const [revLoaderId, setRevLoaderId] = useState("")
    const [revSpeciality, setRevSpeciality] = useState("")
    const [revName, setRevName] = useState("")
    const [revManuAssignId, setRevManuAssignId] = useState("")
    const [revManuId, setRevManuId] = useState("")
    const [revManuTitle, setRevManuTitle] = useState("")
    const [revManuFileId, setRevManuFileId] = useState("")
    const [revManuYear, setRevManuYear] = useState("")
    const [revManuPno, setRevManuPno] = useState("")

    const [revShow, setRevShow] = useState(false)
    const revModalClose = () => {
        setRevShow(false)
        setRevManuAssignId("")
        setRevManuId("")
        setRevManuTitle("")
        setRevManuYear("")
        setRevManuPno("")
        setRevManuFileId("")
        setRevSpeciality("")
        setRevList([])
        setRevLoader()
        setRevLoaderId("")
        setRevName("")
        manuscriptData()
    }
    const revModalShow = async (manuId, manuTitle, manuYear, manuPno, manuFileId) => {
        setRevShow(true)
        // setRevManuAssignId(manuAssignId)
        setRevManuId(manuId)
        setRevManuTitle(manuTitle)
        setRevManuYear(manuYear)
        setRevManuPno(manuPno)
        setRevManuFileId(manuFileId)
        let specRes = await fetch(window.api + "getSpeciality")
        specRes = await specRes.json()
        setSpecialityList(specRes.specialityList)
    }

    async function searchRevByName(name) {

        setRevList([])
        setRevLoader(true)
        setRevName(name)

        const formData = new FormData()
        formData.append('name', name)
        formData.append('spec_id', revSpeciality)
        formData.append('rev_manu_id', revManuId)
        formData.append('rev_manu_file_id', revManuFileId)
        try {
            let revRes = await fetch(window.api + "searchRevByName", {
                method: 'POST',
                body: formData,
            })

            revRes = await revRes.json()
            if (revRes) {
                setRevLoader(false)
                setRevList(revRes.revList)
            }

        } catch (err) {
            console.log(err)
        }
    }

    async function sendToRev(reviewerId, reviewerEmail, reviewerName) {
       
        setRevLoader(true)
        setRevLoaderId(reviewerId)
        const formData = new FormData()
        // formData.append('rev_manu_assign_id', revManuAssignId)
        formData.append('rev_manu_id', revManuId)
        formData.append('rev_manu_title', revManuTitle)
        formData.append('rev_manu_file_id', revManuFileId)
        formData.append('rev_manu_year', revManuYear)
        formData.append('rev_manu_pno', revManuPno)
        formData.append('rev_id', reviewerId)
        formData.append('rev_email', reviewerEmail)
        formData.append('rev_name', reviewerName)
        formData.append('updated_by', userId)

        try {
            let sendData = await fetch(window.api + "sendToRevByEic", {
                method: 'POST',
                body: formData,
            })

            sendData = await sendData.json()
            if (sendData.success) {
                setRevLoaderId("")
                setRevLoader(false)
                searchRevByName(revName)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const [retAuthLoader, setRetAuthLoader] = useState(false)
    const [retAuthManuId, setRetAuthManuId] = useState("")
    const [retAuthManuFileId, setRetAuthManuFileId] = useState("")
    const [retAuthManuTitle, setRetAuthManuTitle] = useState("")
    const [retAuthManuYear, setRetAuthManuYear] = useState("")
    const [retAuthManuPno, setRetAuthManuPno] = useState("")
    const [retAuthName, setRetAuthName] = useState("")
    const [retAuthEmail, setRetAuthEmail] = useState("")

    const [retComtAuthor, setRetComtAuthor] = useState("")
    const [retAuthReplyFile, setRetAuthReplyFile] = useState("")
    const [retAuthReplyFilePath, setRetAuthReplyFilePath] = useState("")
    const [retAuthReplyFileLoader, setRetAuthReplyFileLoader] = useState(false)

    const [retAuthShow, setRetAuthShow] = useState(false)
    const retAuthModalClose = () => {
        setRetAuthShow(false)
        setRetAuthManuId("")
        setRetAuthManuFileId("")
        setRetAuthManuTitle("")
        setRetAuthManuYear("")
        setRetAuthManuPno("")
        setRetAuthName("")
        setRetAuthEmail("")
        setRetComtAuthor("")
        setRetAuthReplyFile("")
        setRetAuthReplyFilePath("")
        manuscriptData()
    }
    const retAuthModalShow = async (manuId, manuFileId, manuTitle, manuYear, manuPno, authName, authEmail) => {
        setRetAuthShow(true)
        setRetAuthManuId(manuId)
        setRetAuthManuFileId(manuFileId)
        setRetAuthManuTitle(manuTitle)
        setRetAuthManuYear(manuYear)
        setRetAuthManuPno(manuPno)
        setRetAuthName(authName)
        setRetAuthEmail(authEmail)
    }

    const handleRetAuthReplyFile = async (file) => {
        setRetAuthReplyFile(file[0])
        setRetAuthReplyFileLoader(true)
        const formData = new FormData
        formData.append('replyFile', file[0])
        try {
            let replyUploadData = await fetch(window.api + "uploadSeRetReplyFile", {
                method: 'POST',
                body: formData,
            })
            replyUploadData = await replyUploadData.json()
            if (replyUploadData.path) {
                setRetAuthReplyFileLoader(false)
                setRetAuthReplyFilePath(replyUploadData.path)
            } else {
                setRetAuthReplyFileLoader(false)
                setRetAuthReplyFilePath("")
            }
        } catch (err) {
            console.log(err)
        }
    }
    const clearSeRetReplyFile = () => {
        setRetAuthReplyFile("")
        setRetAuthReplyFilePath("")
    }

    const returnToAuthorFromSe = async () => {

        setRetAuthLoader(true)
        const formData = new FormData
        formData.append('ret_auth_manu_id', retAuthManuId)
        formData.append('ret_auth_manu_file_id', retAuthManuFileId)
        formData.append('ret_auth_manu_title', retAuthManuTitle)
        formData.append('ret_auth_manu_year', retAuthManuYear)
        formData.append('ret_auth_manu_pno', retAuthManuPno)
        formData.append('ret_auth_se_comments', retComtAuthor)
        formData.append('ret_auth_se_reply_file', retAuthReplyFilePath)
        formData.append('ret_auth_name', retAuthName)
        formData.append('ret_auth_email', retAuthEmail)
        formData.append('updated_by', userId)

        try {
            let retData = await fetch(window.api + "retAuthorFromEic", {
                method: 'POST',
                body: formData,
            })
            retData = await retData.json()
            if (retData.success) {
                setRetAuthLoader(false)
                retAuthModalClose()
            }
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <>
            {loader ? <><Loading loading loaderColor="#22C7B8" /></> : <></>}
            <Modal show={revShow} size="lg" onHide={revModalClose}>
                <Modal.Header>
                    <Modal.Title>Send Manuscript to Reviewers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className='row'>
                                <div className='col-md-4 p-1'>

                                    <select value={revSpeciality} onChange={(e) => setRevSpeciality(e.target.value)} className='form-control'>
                                        <option value="">--Select Speciality--</option>
                                        {Object.keys(specialityList).length > 0 ? specialityList.map((s, index) => (
                                            <option key={index} value={s.id}>{s.name}</option>
                                        )) : <></>}
                                    </select>

                                </div>
                                <div className='col-md-6 p-1'>
                                    <input type="text" value={revName} onChange={(e) => searchRevByName(e.target.value)} className='form-control' placeholder='Search Reviewers by name' />
                                </div>
                                <div className='col-md-2 p-1'>

                                </div>
                            </div>
                            <div className='row'>
                                <table className='table table-striped table-sm'>

                                    {Object.keys(revList).length > 0 ?
                                        <>
                                            <thead>
                                                <tr>
                                                    <th>Name / Email</th>
                                                    <th>Affiliation</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {revList.map((s, index) =>
                                                    <tr key={index}>
                                                        <td>{s.fname} {s.lname}<br />{s.email}</td>
                                                        <td>{s.affiliation}</td>
                                                        <td>
                                                            {(s.assigned_manu == 0) ?
                                                                <button onClick={() => sendToRev(s.id, s.email, s.fname)} className='btn btn-success btn-sm' disabled={revLoader || revLoaderId}>
                                                                    {(revLoader && revLoaderId == s.id) ?
                                                                        'Sending..' : 'send'
                                                                    }
                                                                </button> :
                                                                <i>Assigned</i>}
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </> :
                                        <><table className='table text-center text-danger'><tbody><tr><td>No Data</td></tr></tbody></table></>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={revModalClose} disabled={revLoader || revLoaderId}>
                        Cancle
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={retAuthShow} size="lg" onHide={retAuthModalClose}>
                <Modal.Header>
                    <Modal.Title>Return to Author from S.E</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <div className="form-group">
                                <label>Comments for Author</label>
                                <textarea className="form-control" rows="7" value={retComtAuthor} onChange={(e) => setRetComtAuthor(e.target.value)}></textarea>
                            </div>
                        </div>
                        <div className="col-md-6 col-6">
                            <div className="form-group">
                                <label>Reply File</label>
                                {retAuthReplyFileLoader ?
                                    <>
                                        <p><i className="invalid-alert">Uploading....</i></p>
                                    </> :
                                    <>
                                        {retAuthReplyFilePath ?
                                            <>
                                                <p>
                                                    <Badge bg="success">Reply File Uploaded</Badge><i onClick={clearSeRetReplyFile} className="bi bi-x"></i>
                                                </p>
                                            </> :
                                            <>
                                                <input type="file" className="form-control" onChange={e => handleRetAuthReplyFile(e.target.files)} />
                                            </>}
                                    </>}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={returnToAuthorFromSe} disabled={retAuthLoader || !retComtAuthor}>
                        {retAuthLoader ? 'Returning...' : 'Return'}
                    </Button>
                    <Button variant="danger" onClick={retAuthModalClose} disabled={retAuthLoader}>
                        Cancle
                    </Button>
                </Modal.Footer>
            </Modal>
            {(manuListLoader) ?
                <><i>Fecthing Record Please Wait.....</i></> :
                <>
                    {Object.keys(manuscript).length > 0 ?
                        <>
                            <Table striped bordered size="sm" className="dashboard-table">
                                <thead>
                                    <tr>
                                        <th>Files</th>
                                        <th>Paper No.</th>
                                        <th>Manuscript Title</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {manuscript.map((e, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>
                                                        <button className="btn"
                                                            onClick={
                                                                () => {
                                                                    setOpen((e) => e.map((o, i) => {
                                                                        if (i === index) return !o;
                                                                        return o;
                                                                    })
                                                                    )
                                                                }}
                                                        >
                                                            <i className='fas fa-angle-down'></i>
                                                        </button>
                                                    </td>
                                                    <td>
                                                        {(e.manu_year != 0 && e.manu_pno != 0) ? <>VSOHJ-{e.manu_year}-{e.manu_pno}</> : <></>}
                                                        <br />
                                                        {e.assign_date ? e.assign_date.substr(0, 10) : <></>}
                                                    </td>
                                                    <td>{e.manu_title}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={4}>

                                                        <Collapse in={open[index]}>
                                                            <div className="w-100 ">
                                                                <Table>
                                                                    {e.manu_files.map(f => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                                    <td>{f.created_at.substr(0, 10)}</td>
                                                                                    <td>
                                                                                        {(f.manu_file != null) ?
                                                                                            <a href={window.storage + "downloadManuscript/" + f.manu_file} download={f.manu_file} rel="noreferrer" target="_blank" style={{ color: '#000000' }}>
                                                                                                <i className="bi bi-file-earmark-arrow-down-fill"></i>Manu File
                                                                                            </a>
                                                                                            : <a></a>}
                                                                                    </td>
                                                                                    <td>
                                                                                        {(f.cover_file != null) ?
                                                                                            <a href={window.storage + "downloadCover/" + f.cover_file} download={f.cover_file} rel="noreferrer" target="_blank" style={{ color: '#000000' }}>
                                                                                                <i className="bi bi-file-earmark-arrow-down-fill"></i>Cover File
                                                                                            </a> :
                                                                                            <a></a>}
                                                                                    </td>
                                                                                    <td>
                                                                                        {(f.suplementry_file != null) ?
                                                                                            <a href={window.storage + "downloadSuplementry/" + f.suplementry_file} download={f.suplementry_file} rel="noreferrer" target="_blank" style={{ color: '#000000' }}>
                                                                                                <i className="bi bi-file-earmark-arrow-down-fill"></i>Suplementry File
                                                                                            </a> :
                                                                                            <a></a>}
                                                                                    </td>
                                                                                    

                                                                                    {(f.file_status == 0) ?
                                                                                        <>
                                                                                            <td>
                                                                                                <button className="btn text-success" onClick={() => revModalShow(e.manu_id, e.manu_title, e.manu_year, e.manu_pno, f.manu_file_id)}>Send Rev</button>
                                                                                            </td>
                                                                                            <td>
                                                                                                <button className="btn text-success" onClick={() => retAuthModalShow(e.manu_id, f.manu_file_id, e.manu_title, e.manu_year, e.manu_pno, e.author_name, e.author_email)}>Ret. to Author</button>
                                                                                            </td>
                                                                                        </> :
                                                                                        <><Badge bg="danger">Not Eligible to proceed</Badge></>
                                                                                    }
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </Table>
                                                            </div>
                                                        </Collapse>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </> :
                        <>
                            <div className='text-center'>
                                <i className='text-danger'>No Data</i>
                            </div>
                        </>}
                </>}
        </>
    )
}

export default Manu