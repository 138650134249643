import React, { useState } from 'react'
import Footer from './inc/Footer'
import Header from './inc/Header'
import Sidebar from './inc/Sidebar'
import PublicationManu from './publications/PublicationManu'
import PublishedManu from './publications/PublishedManu'
import Nav from 'react-bootstrap/Nav'

const Publications = () => {

    const [key, setKey] = useState('PublicationManu')

    async function changeComponent(compName) {
        setKey(compName)
    }

    return (
        <>
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <div className="navbar-bg"></div>
                    <Header />
                    <Sidebar />
                    <div className="main-content">
                        <section className="section">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Manuscript Publication</h4>
                                        </div>
                                        <div className="card-body">
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <Nav variant="tabs" defaultActiveKey="link-1">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="link-1" onClick={() => changeComponent('PublicationManu')}>Manu. for Publication</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="link-2" onClick={() => changeComponent('PublishedManu')}>Published Manu.</Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </div>
                                            </div>
                                            <div className='row mt-4'>
                                                <div className='col-md-12'>
                                                    {(key === 'PublicationManu') ?
                                                        <>
                                                            <PublicationManu />
                                                        </> :
                                                        (key === 'PublishedManu') ?
                                                            <>
                                                                <PublishedManu />
                                                            </> :
                                                            <></>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Publications