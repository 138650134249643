import { Route, Routes, BrowserRouter } from "react-router-dom"
import Dashboard from "./component/Dashboard"
import Login from "./component/Login"
import Manuscripts from "./component/Manuscripts"
import Misc from "./component/Misc"
import MEManuscripts from "./component/MEManuscripts"
import Users from "./component/Users"
import CheckApi from "./component/CheckApi"
import RevReport from "./component/RevReport"
import Publications from "./component/Publications"
import PublishPaper from "./component/PublishPaper"
import DMEManuscripts from "./component/DMEManuscripts"
import EICManuscripts from "./component/EICManuscrips"
import ManageUser from "./component/ManageUser"
import Inpres from "./component/Inpres"

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index exact path="/" element={<Dashboard />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Manuscripts" element={<Manuscripts />} />
          <Route path="/Misc" element={<Misc />} />
          <Route path="/MEManuscripts" element={<MEManuscripts />} />
          <Route path="/DMEManuscripts" element={<DMEManuscripts />} />
          <Route path="/Users" element={<Users />} />
          <Route path="/CheckApi" element={<CheckApi />} />
          <Route path="/RevReport" element={<RevReport />} />
          <Route path="/Publications" element={<Publications />} />
          <Route path="/PublishPaper" element={<PublishPaper />} />
          <Route path="/EICManuscripts" element={<EICManuscripts />} />
          <Route path="/ManageUser" element={<ManageUser />} />
          <Route path="/Inpres" element={<Inpres />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App