import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

window.api = "https://api.pakjas.com.pk/api/"
window.storage = "https://api.pakjas.com.pk/"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

