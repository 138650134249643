import React from 'react'
import Footer from './inc/Footer'
import Header from './inc/Header'
import Sidebar from './inc/Sidebar'
import Issues from './misc-comp/Issues'
import Speciality from './misc-comp/Speciality'
import Topics from './misc-comp/Topics'
import Volumes from './misc-comp/Volumes'

const Misc = () => {
    return (
        <>
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <div className="navbar-bg"></div>
                    <Header />
                    <Sidebar />
                    <div className="main-content">
                        <section class="section">
                            <div class="row ">
                                <div class="col-12 col-sm-12 col-lg-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4>Misc Entries</h4>
                                        </div>
                                        <div class="card-body">
                                            <ul class="nav nav-tabs" id="myTab2" role="tablist">
                                                <li class="nav-item">
                                                    <a class="nav-link active" id="speciality-tab2" data-toggle="tab" href="#speciality" role="tab"
                                                        aria-controls="speciality" aria-selected="true">Specialities</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" id="topics-tab2" data-toggle="tab" href="#topics" role="tab"
                                                        aria-controls="topics" aria-selected="false">Topics</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" id="volumes-tab2" data-toggle="tab" href="#volumes" role="tab"
                                                        aria-controls="volumes" aria-selected="false">Volumes</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a class="nav-link" id="issues-tab2" data-toggle="tab" href="#issues" role="tab"
                                                        aria-controls="issues" aria-selected="false">Issues</a>
                                                </li>
                                            </ul>
                                            <div class="tab-content tab-bordered" id="myTab3Content">
                                                <div class="tab-pane fade show active" id="speciality" role="tabpanel" aria-labelledby="speciality-tab2">
                                                    <Speciality />
                                                </div>
                                                <div class="tab-pane fade" id="topics" role="tabpanel" aria-labelledby="topics-tab2">
                                                    <Topics />
                                                </div>
                                                <div class="tab-pane fade" id="volumes" role="tabpanel" aria-labelledby="volumes-tab2">
                                                    <Volumes />
                                                </div>
                                                <div class="tab-pane fade" id="issues" role="tabpanel" aria-labelledby="issues-tab2">
                                                    <Issues />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </section>

                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Misc