import React, { useState, useEffect } from 'react'
import Footer from './inc/Footer'
import Header from './inc/Header'
import Sidebar from './inc/Sidebar'
import Loading from 'react-fullscreen-loading'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

const Users = () => {
    const [loader, setLoader] = useState(false)
    const [userList, setUserList] = useState([])

    async function userData() {
        try {
            const formData = new FormData
            formData.append('list', 1)
            let result = await fetch(window.api + "getAllUsers", {
                method: 'POST',
                body: formData
            })
            result = await result.json()
            if (result) {
                setUserList(result.users)
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        userData()
    }, [])

    const makeReviewer = async (userId) => {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure want to make Reviewer?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => makeR(userId)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    const makeR = async (userId) => {
        try {
            setLoader(true)
            const formData = new FormData
            formData.append('user_id', userId)
            let result = await fetch(window.api + "makeReviewer", {
                method: 'POST',
                body: formData
            })
            result = await result.json()
            if (result.success) {
                userData()
                setLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const terminateReviewer = async (userId) => {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure want to Terminate Reviewer?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => terminateR(userId)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    const terminateR = async (userId) => {
        try {
            setLoader(true)
            const formData = new FormData
            formData.append('user_id', userId)
            let result = await fetch(window.api + "terminateReviewer", {
                method: 'POST',
                body: formData
            })
            result = await result.json()
            if (result.success) {
                userData()
                setLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const makeSe = async (userId) => {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure want to make S.E?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => makeS(userId)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    const makeS = async (userId) => {
        try {
            setLoader(true)
            const formData = new FormData
            formData.append('user_id', userId)
            let result = await fetch(window.api + "makeSe", {
                method: 'POST',
                body: formData
            })
            result = await result.json()
            if (result.success) {
                userData()
                setLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    const terminateSe = async (userId) => {
        confirmAlert({
            title: 'Confirmation',
            message: 'Are you sure want to Terminate S.E?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => terminateS(userId)
                },
                {
                    label: 'No',
                }
            ]
        })
    }

    const terminateS = async (userId) => {
        try {
            setLoader(true)
            const formData = new FormData
            formData.append('user_id', userId)
            let result = await fetch(window.api + "terminateSe", {
                method: 'POST',
                body: formData
            })
            result = await result.json()
            if (result.success) {
                userData()
                setLoader(false)
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            <div id="app">
                <div className="main-wrapper main-wrapper-1">
                    <div className="navbar-bg"></div>
                    <Header />
                    <Sidebar />
                    <div className="main-content">
                        {loader ? <><Loading loading loaderColor="#3498db" /></> : <></>}
                        <section className="section">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>All Users</h4>
                                            {/* <div className="card-header-form">
                                                <form>
                                                    <div className="input-group">
                                                        <input type="text" className="form-control" placeholder="Search" />
                                                        <div className="input-group-btn">
                                                            <button className="btn btn-primary"><i className="fas fa-search"></i></button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div> */}
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                {Object.keys(userList).length > 0 ?
                                                    <>
                                                        <table className="table table-striped table-bordered table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th>Oric Id</th>
                                                                    <th>Name / Specialization</th>
                                                                    <th>Affiliation / Country</th>
                                                                    <th>Mobile / Email</th>
                                                                    <th>Status</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {userList.map((u, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {(u.oric_id == null) ? 'None' : u.oric_id}
                                                                        </td>
                                                                        <td>
                                                                            {u.title_name}. {u.fname} {u.lname}
                                                                            {(u.rev_applied === 'true') ?
                                                                                <i className="i-tag fas fa-bookmark text-success pl-1" title='Applied for Reviewer'></i> :
                                                                                <></>}
                                                                            <br />
                                                                            {u.spec_name}
                                                                        </td>
                                                                        <td>
                                                                            {u.affiliation}<br />
                                                                            {u.country_name}
                                                                        </td>
                                                                        <td>
                                                                            {u.mobile}<br />
                                                                            {u.email}
                                                                        </td>
                                                                        <td>
                                                                            {(u.reviewer == 1) ?
                                                                                <>
                                                                                    <span>Reviewer</span> <i onClick={() => terminateReviewer(u.id)} className='fas fa-ban i-tag' title='Terminate'></i><br />
                                                                                </> :
                                                                                <></>}
                                                                            {(u.section_editor == 1) ?
                                                                                <>
                                                                                    <span>Section Editor</span> <i onClick={() => terminateSe(u.id)} className='fas fa-ban i-tag' title='Terminate'></i>
                                                                                </> :
                                                                                <></>}
                                                                        </td>
                                                                        <td>
                                                                            {(u.reviewer == 1 && u.section_editor == 1) ?
                                                                                <>No Action</> :
                                                                                <>
                                                                                    <button class="btn btn-success dropdown-toggle" type="button" data-toggle="dropdown"
                                                                                        aria-haspopup="true" aria-expanded="false">
                                                                                        Actions
                                                                                    </button>
                                                                                    <div class="dropdown-menu">
                                                                                        {(u.reviewer == 0) ?
                                                                                            <>
                                                                                                <i class="dropdown-item i-tag" onClick={() => makeReviewer(u.id)}>Make Reviewer</i>
                                                                                            </> :
                                                                                            <></>}
                                                                                        {(u.section_editor == 0) ?
                                                                                            <>
                                                                                                <i class="dropdown-item i-tag" onClick={() => makeSe(u.id)}>Make S.E</i>
                                                                                            </> :
                                                                                            <></>}
                                                                                    </div>
                                                                                </>}

                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </> :
                                                    <>
                                                        <center>No Data</center>
                                                    </>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default Users