import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Table from 'react-bootstrap/Table'

const PubManu = () => {

    const [pubManuListLoader, setPubManuListLoader] = useState(false)

    const [pubManuscript, setPubManuscript] = useState([])
    const [manuscriptAuthors, setManuscriptAuthors] = useState([])

    const [authorManuId, setAuthorManuId] = useState([])
    const [authorShow, setAuthorShow] = useState(false)
    const authorModalClose = () => setAuthorShow(false)
    const authorModalShow = (authors, manuId) => {
        setAuthorManuId(manuId)
        setManuscriptAuthors(authors)
        setAuthorShow(true)
    }

    async function sePubManuscriptData() {
        try {
            setPubManuListLoader(true)
            let datares = await fetch(window.api + "getAllMePubManuAdmin")
            datares = await datares.json()
            if (datares) {
                setPubManuscript(datares.publication_manu)
                setPubManuListLoader(false)
            }
        } catch (err) {
            console.log(err);
        }
    }

    React.useEffect(() => {
        window.scrollTo(0, 0)
        sePubManuscriptData()
    }, [])

    const getReviewingReport = async (manuId) => {
        setFetchRepLoader(true)
        const formData = new FormData
        formData.append('manu_id', manuId)
        let result = await fetch(window.api + "getReviewingReport", {
            method: 'POST',
            body: formData
        })
        result = await result.json()
        if (result) {
            setFetchRepLoader(false)
            setRepManuData(result.all_reviewing)
        }
    }

    const [fetchRepLoader, setFetchRepLoader] = useState(false)
    const [repManuId, setRepManuId] = useState("")
    const [repManuFileId, setRepManuFileId] = useState("")
    const [repManuTitle, setRepManuTitle] = useState("")
    const [repManuYear, setRepManuYear] = useState("")
    const [repManuPno, setRepManuPno] = useState("")
    const [repManuData, setRepManuData] = useState([])
    const [repShow, setRepShow] = useState(false)
    const repModalClose = () => {
        setRepShow(false)
        setRepManuId("")
        setRepManuFileId("")
        setRepManuTitle("")
        setRepManuYear("")
        setRepManuPno("")
        setRepManuData([])
    }
    const repModalShow = async (manuId, manuFileId, manuTitle, manuYear, manuPno) => {

        setFetchRepLoader(true)
        setRepShow(true)
        setRepManuId(manuId)
        setRepManuFileId(manuFileId)
        setRepManuTitle(manuTitle)
        setRepManuYear(manuYear)
        setRepManuPno(manuPno)

        getReviewingReport(manuId)
        // const formData = new FormData
        // formData.append('manu_id', manuId)
        // let result = await fetch(window.api + "getReviewingReport", {
        //     method: 'POST',
        //     body: formData
        // })
        // result = await result.json()
        // if (result) {
        //     setFetchRepLoader(false)
        //     setRepManuData(result.all_reviewing)
        // }
    }

    // Issue Certificate 

    const [cerLoader, setCerLoader] = useState(false)

    async function issueCertificate(assignId, manuId) {
        setCerLoader(true)
        const formData = new FormData()
        formData.append('cer_assign_id', assignId)

        try {
            let issueData = await fetch(window.api + "issueCertificate", {
                method: 'POST',
                body: formData,
            })

            issueData = await issueData.json()
            if (issueData.success) {
                setCerLoader(false)
                getReviewingReport(manuId)
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <>
            <Modal show={authorShow} onHide={authorModalClose}>
                <Modal.Header>
                    <Modal.Title>Author Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 col-12">
                            <table className='table table-striped'>
                                <thead>
                                    <th>Name / Email</th>
                                    <th>Affiliation</th>
                                </thead>
                                {(authorManuId < 14245) ?
                                    <>
                                        {Object.keys(manuscriptAuthors).length > 0 ? manuscriptAuthors.map(ad => (
                                            <tbody>
                                                <tr>
                                                    <td>{ad.firstName + ' ' + ad.lastName}<br />{ad.email} </td>
                                                    <td>{ad.affiliation}</td>
                                                </tr>
                                            </tbody>
                                        )) : <></>}
                                    </> :
                                    <>
                                        {Object.keys(manuscriptAuthors).length > 0 ? manuscriptAuthors.map(ad => (
                                            <tbody>
                                                <tr>
                                                    <td>{ad.author_name}<br />{ad.author_email}</td>
                                                    <td>{ad.author_affiliation}</td>
                                                </tr>
                                            </tbody>
                                        )) : <></>}
                                    </>}
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={authorModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={repShow} size="xl" onHide={repModalClose}>
                <Modal.Header>
                    <Modal.Title>Reviewing Report for Manuscript</Modal.Title>
                    <Button variant="danger" onClick={repModalClose}>Close</Button>
                </Modal.Header>
                <Modal.Body scrollable={true}>
                    <div className="scrollbar" id="style-1">
                        <div className="container-fluid">
                            {(fetchRepLoader) ?
                                <>
                                    <div className="row">
                                        <div className="col-md-12 text-center">
                                            <i className="text-info">Fetching Record Please Wait..</i>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div className="row">
                                        <div className="col-md-2 col-2 p-0">

                                            <img alt="image" src="assets/img/logo.png" width="100px" />

                                        </div>
                                        <div className="col-md-8 col-8 p-0 report-heading">
                                            <h2>Pakistan Journal of Agricultural Sciences</h2>
                                        </div>
                                        <div className="col-md-2 col-2 p-0">

                                            {(repManuYear != 0 && repManuPno != 0) ?
                                                <>
                                                    <h5>PJAS-{repManuYear}-{repManuPno}</h5>
                                                </> :
                                                <></>}
                                        </div>


                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-12">
                                            <center>
                                                <h4>{repManuTitle}</h4>
                                            </center>
                                        </div>
                                    </div>

                                    {Object.keys(repManuData).length > 0 ?
                                        <>
                                            {repManuData.map((m, index) => {
                                                return (
                                                    <>
                                                        <Table bordered size="sm" className='border border-dark'>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <b>Manu</b>
                                                                        {(m.file_status == 2) ?
                                                                            <>
                                                                                <span class="text-success"> (Eligible)</span>
                                                                            </> :
                                                                            <></>}
                                                                    </td>
                                                                    <td>
                                                                        {(m.manu_file != null) ?
                                                                            <a href={window.storage + "downloadManuscript/" + m.manu_file} download={m.manu_file} rel="noreferrer" target="_blank">
                                                                                {/* <i className="fas fa-file-download"></i>Manu File */}
                                                                                <i className="fas fa-file-download"></i> Download
                                                                            </a>
                                                                            : <i>No File</i>}

                                                                    </td>
                                                                    <td><b>Supplementry</b></td>
                                                                    <td>
                                                                        {(m.suplementry_file != null) ?
                                                                            <a href={window.storage + "downloadSuplementry/" + m.suplementry_file} download={m.suplementry_file} rel="noreferrer" target="_blank">
                                                                                <i className="fas fa-file-download"></i> Download
                                                                            </a> :
                                                                            <i className='text-info'>No File</i>}
                                                                    </td>
                                                                    <td><b>Cover</b></td>
                                                                    <td>
                                                                        {(m.cover_file != null) ?
                                                                            <a href={window.storage + "downloadCover/" + m.cover_file} download={m.cover_file} rel="noreferrer" target="_blank">
                                                                                <i className="fas fa-file-download"></i> Download
                                                                            </a> :
                                                                            <i className='text-info'>No File</i>}
                                                                    </td>
                                                                </tr>
                                                                {(m.me_comments == null || m.me_comments == "") ?
                                                                    <></> :
                                                                    <>
                                                                        <tr>
                                                                            <td style={{ width: '12%' }}>Managing Editor</td>
                                                                            <td colSpan={5}>
                                                                                {(m.me_file != null) ?
                                                                                    <a href={window.storage + "downloadReply/" + m.me_file} download={m.me_file} rel="noreferrer" target="_blank">
                                                                                        <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reply File
                                                                                    </a> : <a></a>}<br />
                                                                                <b>Comments:</b> {m.me_comments}
                                                                            </td>
                                                                        </tr>
                                                                    </>}
                                                                {(m.ret_se_comments == null || m.ret_se_comments == "") ?
                                                                    <></> :
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={6}>
                                                                                <center><b>Comments to Author</b></center>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '12%' }}>Section Editor</td>
                                                                            <td colSpan={5}>
                                                                                {(m.ret_se_file != null) ?
                                                                                    <a href={window.storage + "downloadReply/" + m.ret_se_file} download={m.ret_se_file} rel="noreferrer" target="_blank">
                                                                                        <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reply File
                                                                                    </a> : <a></a>}<br />
                                                                                <b>Comments:</b> {m.ret_se_comments}
                                                                            </td>
                                                                        </tr>
                                                                    </>}
                                                                {(m.edi_comments == null || m.edi_comments == "") ?
                                                                    <></> :
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={6}>
                                                                                <center><b>Comments to Author</b></center>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '12%' }}>Editorial Office</td>
                                                                            <td colSpan={5}>
                                                                                {(m.edi_file != null) ?
                                                                                    <a href={window.storage + "downloadReply/" + m.edi_file} download={m.edi_file} rel="noreferrer" target="_blank">
                                                                                        <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reply File
                                                                                    </a> : <a></a>}<br />
                                                                                <b>Comments:</b> {m.edi_comments}
                                                                            </td>
                                                                        </tr>
                                                                    </>}
                                                                {(m.se_accept == null || m.se_accept == "") ?
                                                                    <></> :
                                                                    <>
                                                                        <tr>
                                                                            <td colSpan={6}>
                                                                                <center><b>Comments to M.E</b></center>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '12%' }}>Section Editor</td>
                                                                            <td colSpan={5}>
                                                                                {(m.se_file != null) ?
                                                                                    <>
                                                                                        <a href={window.storage + "downloadReply/" + m.se_file} download={m.se_file} rel="noreferrer" target="_blank">
                                                                                            <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reply File
                                                                                        </a><br /></> : <></>}
                                                                                <b>Accept Paper? </b>({m.se_accept})<br />
                                                                                <b>Comments:</b> {m.se_comments}
                                                                            </td>
                                                                        </tr>
                                                                    </>}
                                                                {Object.keys(m.assign_data).length > 0 ?
                                                                    <>
                                                                        <tr>
                                                                            <th style={{ width: '9%' }}>Reviewed By</th>
                                                                            <th colSpan={5}><center>Decision</center></th>
                                                                        </tr>
                                                                        {m.assign_data.map((e, index) => {
                                                                            return (
                                                                                <>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b>{e.rev_fname} {(e.rev_lname !== 's') ? e.rev_lname : ''}</b><br />
                                                                                            {e.rev_affiliation}<br />
                                                                                            {e.created_at ? e.created_at.substr(0, 10) : <></>}
                                                                                            {(e.assign_status == 3) ?
                                                                                                <>
                                                                                                    {(e.cer_status == 1) ?
                                                                                                        <>
                                                                                                            <span className='text-success'>Certificate Issed</span>
                                                                                                        </> :
                                                                                                        <>
                                                                                                            <button
                                                                                                                onClick={() => issueCertificate(e.assign_id, repManuId)}
                                                                                                                className='btn btn-success btn-sm'
                                                                                                                disabled={cerLoader}
                                                                                                            >
                                                                                                                {cerLoader ? 'Issuing..' : 'Issue Certificate'}
                                                                                                            </button>
                                                                                                        </>}
                                                                                                </> :
                                                                                                <></>}
                                                                                        </td>
                                                                                        <td colSpan={5}>
                                                                                            {(e.assign_status == 0) ?
                                                                                                <><i className="text-danger">Reviewer Rejected Manuscript</i></> :
                                                                                                (e.assign_status == 1) ?
                                                                                                    <><i className="text-warning">Pending for Acceptance</i></> :
                                                                                                    (e.assign_status == 2) ?
                                                                                                        <><i className="text-info">Decision Pending</i></> :
                                                                                                        (e.assign_status == 3) ?
                                                                                                            <>
                                                                                                                <div className="row">
                                                                                                                    <div className="col-sm-6"><b>Do you want get credit for reviewing this manuscript?</b> ({e.paid})</div>
                                                                                                                    <div className="col-sm-6"><b>Subjects and contents are suitable for publications?</b> ({e.q1})</div>
                                                                                                                    <div className="col-sm-6"><b>Novelty of results is appropriate?</b> ({e.q2})</div>
                                                                                                                    <div className="col-sm-6"><b>Conclusions are well proven?</b> ({e.q3})</div>
                                                                                                                    <div className="col-sm-6"><b>Title is suitable?</b> ({e.q4})</div>
                                                                                                                    <div className="col-sm-6"><b>Number and quality of references are appropriate?</b> ({e.q5})</div>
                                                                                                                    <div className="col-sm-6"><b>The linguistic quality of paper is good?</b> ({e.q6})</div>
                                                                                                                    <div className="col-sm-6"><b>Reference is provided where data are reported?</b> ({e.q7})</div>
                                                                                                                </div>
                                                                                                                <hr />
                                                                                                                <div className="row">
                                                                                                                    <div className="col-md-6">
                                                                                                                        <b>Recomendations:  </b>
                                                                                                                        {
                                                                                                                            (e.r === 'r1') ?
                                                                                                                                <>
                                                                                                                                    <span>Accept in Present Form</span>
                                                                                                                                </> :
                                                                                                                                (e.r === 'r2') ?
                                                                                                                                    <>
                                                                                                                                        <span>Consider After Minor Revision</span>
                                                                                                                                    </> :
                                                                                                                                    (e.r === 'r3') ?
                                                                                                                                        <>
                                                                                                                                            <span>Reassess After Major Revision</span>
                                                                                                                                        </> :
                                                                                                                                        (e.r === 'r4') ?
                                                                                                                                            <>
                                                                                                                                                <span>Reject</span>
                                                                                                                                            </> :
                                                                                                                                            <></>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className="col-md-6">
                                                                                                                        {(e.reply_file_path != null) ?
                                                                                                                            <a href={window.storage + "downloadRevReply/" + e.reply_file_path} download={e.reply_file_path} rel="noreferrer" target="_blank" style={{ color: '#000000' }}>
                                                                                                                                <i className="bi bi-file-earmark-arrow-down-fill"></i> Download Reviewer Reply File
                                                                                                                            </a> : <a></a>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <hr />
                                                                                                                <div className="row">
                                                                                                                    <div className="col-sm-12">
                                                                                                                        <b>Confidential Comments to Executive Editor: </b>{e.ex_co}
                                                                                                                    </div>
                                                                                                                    <div className="col-sm-12">
                                                                                                                        <b>Comments to the Author: </b>{e.aut_co}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </> :
                                                                                                            <><i>Not Replied Yet</i></>}
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </> :
                                                                    <><tr><td>No Data</td></tr></>}
                                                            </tbody>
                                                        </Table>
                                                        {/* <hr /> */}
                                                    </>
                                                )
                                            })}
                                        </> :
                                        <><tr><td>No Data</td></tr></>}

                                </>}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {(pubManuListLoader) ?
                <><i>Fetching Record Please Wait....</i></> :
                <>
                    {Object.keys(pubManuscript).length > 0 ?
                        <>
                            <div className="table-responsive">
                                <table className="table table-sm table-striped table-bordered se-pro-manu">
                                    <thead>
                                        <tr>
                                            <th>Sr#</th>
                                            <th>Title / Author</th>
                                            <th>File</th>
                                            <th>S.E List</th>
                                            <th>Report</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pubManuscript.map(m => (
                                            <tr key={m}>
                                                <td>
                                                    {(m.manu_year == 0 && m.manu_pno == 0) ? m.manu_id : <>{"PJAS-" + m.manu_year + "-" + m.manu_pno}</>}<br />
                                                    {m.manu_date.substr(0, 10)}
                                                </td>
                                                <td>
                                                    {m.manu_title}<br />
                                                    {(m.manu_id < 14245) ?
                                                        <>
                                                            {(m.manu_prvious_authors === null) ?
                                                                <>
                                                                </> :
                                                                <>
                                                                    <span className="icon-hand" onClick={() => authorModalShow(m.manu_prvious_authors, m.manu_id)}><i className='fas fa-user'></i>
                                                                        {m.manu_prvious_authors.map(pa => (
                                                                            pa.firstName + ' ' + pa.lastName + ', '
                                                                        ))}
                                                                    </span>
                                                                </>}
                                                        </> :
                                                        <>
                                                            <span className="icon-hand" onClick={() => authorModalShow(m.manu_authors, m.manu_id)}><i className='fas fa-user'></i>
                                                                {Object.keys(m.manu_authors).length > 0 ? m.manu_authors.map(a => (
                                                                    <> {a.author_name},</>
                                                                )) : <></>}
                                                            </span>
                                                        </>}<br />
                                                        {m.manu_user_email}*
                                                </td>
                                                <td>
                                                    {(m.manu_file_id > 16100) ?
                                                        <>
                                                            <a href={window.storage + "downloadManuscript/" + m.manu_file} target="_blank" rel="noreferrer" download={m.manu_file}>
                                                                <i style={{ fontSize: '20px' }} className="fas fa-file-download"></i>
                                                            </a>
                                                        </> :
                                                        <>
                                                            {(m.manu_file) ?
                                                                <>
                                                                    <a href={window.storage + "downloadManuscript/" + m.manu_file.substring(20)} target="_blank" rel="noreferrer" download={m.manu_file}>
                                                                        <i style={{ fontSize: '20px' }} className="fas fa-file-download"></i>
                                                                    </a>
                                                                </> :
                                                                <></>}
                                                        </>}
                                                </td>
                                                <td>
                                                    {Object.keys(m.manu_se).length > 0 ? m.manu_se.map(s => (
                                                        <>
                                                            {s.se_name}
                                                            {(s.se_manu_status == 0) ?
                                                                <>
                                                                    <i className='icon-property-user-info fas fa-info-circle text-danger' title='Reversed from S.E'></i>
                                                                </> :
                                                                (s.se_manu_status == 3) ?
                                                                    <>
                                                                        <i className='icon-property-user-info fas fa-info-circle text-success' title='Processed by S.E'></i>
                                                                    </> :
                                                                    <></>}
                                                            <br />
                                                        </>
                                                    )) : <></>}
                                                </td>
                                                <td>
                                                    <i className="icon-property-info" title='View Reviewing Report' onClick={() => repModalShow(m.manu_id, m.manu_file_id, m.manu_title, m.manu_year, m.manu_pno)}>View</i>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </> :
                        <>
                            <div className='text-center'>
                                <i className='text-danger'>No Data</i>
                            </div>
                        </>}
                </>}
        </>
    )
}

export default PubManu