import React, { useState, useEffect } from 'react'
import Footer from './inc/Footer'
import Header from './inc/Header'
import Sidebar from './inc/Sidebar'
import Loading from 'react-fullscreen-loading'

const Dashboard = () => {

  const [countUser, setCountUser] = useState("")
  const [countSe, setCountSe] = useState("")
  const [countRev, setCountRev] = useState("")
  const [countManu, setCountManu] = useState("")
  const [countPubManu, setCountPubManu] = useState("")
  const [countVolume, setCountVolume] = useState("")
  const [countIssue, setCountIssue] = useState("")
  const [countPaper, setCountPaper] = useState("")
  const [loader, setLoader] = useState(false)

  async function countDataForDashboard() {
    setLoader(true)
    const formData = new FormData()
    formData.append('list', 1)

    try {
      let dataRes = await fetch(window.api + "countDataForDashboard", {
        method: 'POST',
        body: formData,
      })

      dataRes = await dataRes.json()
      if (dataRes) {
        setLoader(false)
        setCountUser(dataRes.user_count)
        setCountSe(dataRes.se_count)
        setCountRev(dataRes.rev_count)
        setCountManu(dataRes.manu_count)
        setCountPubManu(dataRes.pub_manu_count)
        setCountVolume(dataRes.volume_count)
        setCountIssue(dataRes.issue_count)
        setCountPaper(dataRes.paper_count)
      }
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0)
    countDataForDashboard()
  }, [])

  return (
    <>
      {/* <div className='has-sidebar has-fixed-sidebar-and-header'>
        <Header />
        <main className="main">
          <Sidebar />

          <div className="content">
            <div className="py-4 px-3 px-md-4">

              <div className="mb-3 mb-md-4 d-flex justify-content-between">
                <div className="h3 mb-0">Dashboard</div>
              </div>

              <div className="row">

                <div className="col-md-6 col-xl-4 mb-3 mb-xl-4">
                  <div className="card flex-row align-items-center p-3 p-md-4">
                    <div className="icon icon-lg bg-soft-primary rounded-circle mr-3">
                      <i className="gd-bar-chart icon-text d-inline-block text-primary"></i>
                    </div>
                    <div>
                      <h4 className="lh-1 mb-1">75%</h4>
                      <h6 className="mb-0">Conversion Rate</h6>
                    </div>
                    <i className="gd-arrow-up icon-text d-flex text-success ml-auto"></i>
                  </div>
                </div>

                <div className="col-md-6 col-xl-4 mb-3 mb-xl-4">
                  <div className="card flex-row align-items-center p-3 p-md-4">
                    <div className="icon icon-lg bg-soft-secondary rounded-circle mr-3">
                      <i className="gd-wallet icon-text d-inline-block text-secondary"></i>
                    </div>
                    <div>
                      <h4 className="lh-1 mb-1">$18,000.00</h4>
                      <h6 className="mb-0">Total Sales</h6>
                    </div>
                    <i className="gd-arrow-down icon-text d-flex text-danger ml-auto"></i>
                  </div>
                </div>

                <div className="col-md-6 col-xl-4 mb-3 mb-xl-4">
                  <div className="card flex-row align-items-center p-3 p-md-4">
                    <div className="icon icon-lg bg-soft-warning rounded-circle mr-3">
                      <i className="gd-money icon-text d-inline-block text-warning"></i>
                    </div>
                    <div>
                      <h4 className="lh-1 mb-1">$10,000.00</h4>
                      <h6 className="mb-0">Net Revenue</h6>
                    </div>
                    <i className="gd-arrow-up icon-text d-flex text-success ml-auto"></i>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="col-md-6 col-xl-4 mb-3 mb-xl-4">
                  <div className="card flex-row align-items-center p-3 p-md-4">
                    <div className="icon icon-lg bg-soft-warning rounded-circle mr-3">
                      <i className="gd-money icon-text d-inline-block text-warning"></i>
                    </div>
                    <div>
                      <h4 className="lh-1 mb-1">$10,000.00</h4>
                      <h6 className="mb-0">Net Revenue</h6>
                    </div>
                    <i className="gd-arrow-up icon-text d-flex text-success ml-auto"></i>
                  </div>
                </div>
                <div className="col-md-6 col-xl-4 mb-3 mb-xl-4">
                  <div className="card flex-row align-items-center p-3 p-md-4">
                    <div className="icon icon-lg bg-soft-warning rounded-circle mr-3">
                      <i className="gd-money icon-text d-inline-block text-warning"></i>
                    </div>
                    <div>
                      <h4 className="lh-1 mb-1">$10,000.00</h4>
                      <h6 className="mb-0">Net Revenue</h6>
                    </div>
                    <i className="gd-arrow-up icon-text d-flex text-success ml-auto"></i>
                  </div>
                </div>
                <div className="col-md-6 col-xl-4 mb-3 mb-xl-4">
                  <div className="card flex-row align-items-center p-3 p-md-4">
                    <div className="icon icon-lg bg-soft-warning rounded-circle mr-3">
                      <i className="gd-money icon-text d-inline-block text-warning"></i>
                    </div>
                    <div>
                      <h4 className="lh-1 mb-1">$10,000.00</h4>
                      <h6 className="mb-0">Net Revenue</h6>
                    </div>
                    <i className="gd-arrow-up icon-text d-flex text-success ml-auto"></i>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </main>
      </div> */}

      {/* <div className="loader"></div> */}
      <div id="app">
        <div className="main-wrapper main-wrapper-1">
          <div className="navbar-bg"></div>
          <Header />
          <Sidebar />

          <div className="main-content">
            {loader ? <><Loading loading loaderColor="#3498db" /></> : <></>}
            <section class="section">
              <div class="row ">
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-statistic-4">
                      <div class="align-items-center justify-content-between">
                        <div class="row ">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="card-content">
                              <div className='row text-center text-color1'>
                                <div className='col-md-4 p-1'>
                                  <i className='fas fa-users main-icon-info'></i>
                                </div>
                                <div className='col-md-8 p-1'>
                                  <h5 class="font-15">Registered Users</h5>
                                  <h2 class="mb-3 font-18">{countUser}</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-statistic-4">
                      <div class="align-items-center justify-content-between">
                        <div class="row ">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="card-content">
                              <div className='row text-center text-color2'>
                                <div className='col-md-4 p-1'>
                                  <i className='fas fa-user-friends main-icon-info'></i>
                                </div>
                                <div className='col-md-8 p-1'>
                                  <h5 class="font-15">Section Editors</h5>
                                  <h2 class="mb-3 font-18">{countSe}</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-statistic-4">
                      <div class="align-items-center justify-content-between">
                        <div class="row ">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="card-content">
                              <div className='row text-center text-color3'>
                                <div className='col-md-4 p-1'>
                                  <i className='fas fa-user-friends main-icon-info'></i>
                                </div>
                                <div className='col-md-8 p-1'>
                                  <h5 class="font-15">Reviewers</h5>
                                  <h2 class="mb-3 font-18">{countRev}</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-statistic-4">
                      <div class="align-items-center justify-content-between">
                        <div class="row ">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="card-content">
                              <div className='row text-center text-color4'>
                                <div className='col-md-4 p-1'>
                                  <i className='fas fa-desktop main-icon-info'></i>
                                </div>
                                <div className='col-md-8 p-1'>
                                  <h5 class="font-15">All Manuscripts</h5>
                                  <h2 class="mb-3 font-18">{countManu}</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-statistic-4">
                      <div class="align-items-center justify-content-between">
                        <div class="row ">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="card-content">
                              <div className='row text-center text-color5'>
                                <div className='col-md-4 p-1'>
                                  <i className='fas fa-newspaper main-icon-info'></i>
                                </div>
                                <div className='col-md-8 p-1'>
                                  <h5 class="font-15">Published</h5>
                                  <h2 class="mb-3 font-18">{countPubManu}</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-statistic-4">
                      <div class="align-items-center justify-content-between">
                        <div class="row ">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="card-content">
                              <div className='row text-center text-color6'>
                                <div className='col-md-4 p-1'>
                                  <i className='fas fa-file-contract main-icon-info'></i>
                                </div>
                                <div className='col-md-8 p-1'>
                                  <h5 class="font-15">Volumes</h5>
                                  <h2 class="mb-3 font-18">{countVolume}</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-statistic-4">
                      <div class="align-items-center justify-content-between">
                        <div class="row ">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="card-content">
                              <div className='row text-center text-color7'>
                                <div className='col-md-4 p-1'>
                                  <i className='fas fa-file-contract main-icon-info'></i>
                                </div>
                                <div className='col-md-8 p-1'>
                                  <h5 class="font-15">Issues</h5>
                                  <h2 class="mb-3 font-18">{countIssue}</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="card">
                    <div class="card-statistic-4">
                      <div class="align-items-center justify-content-between">
                        <div class="row ">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="card-content">
                              <div className='row text-center text-color8'>
                                <div className='col-md-4 p-1'>
                                  <i className='fas fa-file-pdf main-icon-info'></i>
                                </div>
                                <div className='col-md-8 p-1'>
                                  <h5 class="font-15">Papers</h5>
                                  <h2 class="mb-3 font-18">{countPaper}</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default Dashboard